import React, { useEffect, useState } from 'react';
import { Link, LinkProps, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  TbCalendar,
  TbChevronRight,
  TbCircleCheck,
  TbMailOpened,
  TbNotification,
  TbSettingsCheck,
  TbUserCheck,
  TbUsers,
} from 'react-icons/tb';
import { Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { FaCircleCheck, FaRegCircle } from 'react-icons/fa6';
import { twMerge } from 'tailwind-merge';
import PageHeader from '../components/misc/PageHeader';
import { Event, EventViewType } from '../../types/event';
import useSelectedBusiness from '../../hooks/business/useSelectedBusiness';
import Button from '../components/buttons/Button';
import connectionService from '../../services/connectionService';
import { RootState } from '../../redux/reducers';
import eventService from '../../services/eventService';
import useEvents from '../../hooks/business/useEvents';
import OverviewCard from '../components/cards/OverviewCard';
import ProgressBar from '../components/misc/ProgressBar';
import { isConnectionVerafied } from '../../utils/connectionUtils';
import { COLOR_PRIMARY_300, COLOR_PRIMARY_900, COLOR_SECONDARY_200 } from '../../constants';
import communicationsService from '../../services/communicationsService';
import useCommunications from '../../hooks/business/useCommunications';
import {
  CommunicationType,
  ScheduledEmail,
  ScheduledNotification,
} from '../../types/communication';
import Avatar from '../components/misc/Avatar';
import { formatDateAndTime } from '../../utils/stringUtils';
import { taskService } from '../../services/taskService';
import useTasks from '../../hooks/business/useTasks';
import FeedbackCards from '../components/cards/FeedbackCards';
import EmptyScreenView from '../components/misc/EmptyScreenView';
import useIsScreenSize from '../../hooks/effects/useIsScreenSize';

export default function OverviewPage() {
  const business = useSelectedBusiness();
  const { t } = useTranslation();
  const {
    application: { onboardingStep, firstName },
    connections,
  } = useSelector((state: RootState) => state);
  const role = useSelector((state: RootState) => state.application.role);
  const navigate = useNavigate();
  const isLg = useIsScreenSize('lg');
  const allEvents = useEvents(EventViewType.ALL_REGULAR);
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(false);

  useEffect(() => {
    const hasInviteFields = connections.length >= 1;
    const hasFiveMembers = connections.length >= 5;
    const hasAnEvent = allEvents.length > 0;

    setHasCompletedOnboarding(hasInviteFields && hasFiveMembers && hasAnEvent);
  }, [allEvents, business?.businessId, connections]);

  useEffect(() => {
    if (onboardingStep !== undefined) navigate('/contacts');
  }, []);

  useEffect(() => {
    if (business?.businessId !== undefined) {
      connectionService.getConnections(true);
      eventService.getUpcomingEvents().then(() => eventService.prependPastEvents());
      communicationsService.getNotifications();
      communicationsService.getEmails();
      taskService.getAllTasksForBusiness(business.businessId);
    }
  }, [business?.businessId]);

  if (!business) return <></>;

  return (
    <>
      <PageHeader
        title={t('page.overview.greeting', { name: firstName })}
        subtitle={t('page.overview.subtitle')}
        className="pb-4"
      />
      <div className="flex w-full gap-4 max-lg:flex-col">
        <div className="flex min-w-0 flex-1 flex-col gap-4">
          {hasCompletedOnboarding ? (
            <div className="flex gap-4 max-md:flex-col">
              <MembersCard />
              <AttendanceCard />
            </div>
          ) : (
            <SetupCard />
          )}
          {!isLg && <EventsCard />}
        </div>
        <div className="flex min-w-0 flex-1 flex-col gap-4">
          {!hasCompletedOnboarding && (
            <div className="flex gap-4 max-md:flex-col">
              <MembersCard />
              <AttendanceCard />
            </div>
          )}
          {isLg && <EventsCard />}
          <div className="flex gap-4 max-md:flex-col">
            <NotificationsCard />
            <EmailsCard />
          </div>
          <TasksCard />
        </div>
      </div>

      {role === 'ADMIN' && (
        <div className="mt-10">
          <div className="relative h-80 w-full rounded-md bg-gradient-to-br from-primary-300 to-secondary p-4 before:absolute before:inset-0 before:animate-blur-breathe before:bg-gradient-to-br before:from-primary-300 before:to-secondary before:content-['']">
            <div className="absolute left-1 top-1 flex h-[19.5rem] w-[calc(100%-0.5rem)] flex-col rounded-md bg-primary-900 p-4 text-secondary-50">
              <h2 className="font-serif text-2xl text-secondary-50">🚀 A race to the top!</h2>
              <p className="w-full text-secondary-50 md:w-[40rem]">
                Show that your association is the best by inviting as many participating communities
                as possible! We offer you a free month of PRO subscription for every participating
                community that is a part of your association. Terms and conditions apply.
              </p>
              <div className="mt-4 flex flex-1 flex-wrap items-end gap-2 self-end">
                <Button onClick={() => navigate('/race')} className="text-secondary-50">
                  Learn more
                </Button>
                <Button className="bg-gradient-to-br from-primary-300 to-secondary-200 text-primary-900 shadow-sm shadow-primary-300 hover:shadow-md hover:shadow-primary-300">
                  Join now!
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <FeedbackCards className="mt-8 gap-4 px-0" />
    </>
  );
}

function NotificationsCard(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const communications = useCommunications();
  const notifications = communications?.notifications ?? ([] as ScheduledNotification[]);
  const sentNotificationsPercentage =
    notifications.length === 0
      ? 0
      : Math.round((notifications.filter((n) => n.isSent).length / notifications.length) * 100);

  return (
    <OverviewCard
      icon={TbNotification}
      title={t('pushNotifications')}
      className="min-h-64 flex-1"
      mainFigure={notifications.length}
      seeMoreText={t('seeMore')}
      seeMoreLinkProps={{ to: '/communication' }}
      createLinkProps={{
        to: '/communication/create',
        state: { type: CommunicationType.NOTIFICATION },
      }}>
      <ProgressBar
        progress={sentNotificationsPercentage}
        variant="primary-light"
        showText={false}
      />
      <p className="text-gray-500">
        {t('notificationsSent', { percentage: sentNotificationsPercentage })}
      </p>
    </OverviewCard>
  );
}

function EmailsCard(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const communications = useCommunications();
  const emails = communications?.emails ?? ([] as ScheduledEmail[]);
  const sentEmails = emails.filter((e) => e.isSent).length;
  const sentEmailsPercentage =
    emails.length === 0 ? 0 : Math.round((sentEmails / emails.length) * 100);

  return (
    <OverviewCard
      icon={TbMailOpened}
      title={t('emailsSent')}
      className="min-h-64 flex-1"
      mainFigure={sentEmails}
      seeMoreText={t('seeMore')}
      seeMoreLinkProps={{ to: '/communication' }}
      createLinkProps={{ to: '/communication/create', state: { type: CommunicationType.EMAIL } }}>
      <ProgressBar progress={sentEmailsPercentage} variant="primary-light" showText={false} />
      <p className="text-gray-500">{t('emailsDelivered', { percentage: sentEmailsPercentage })}</p>
    </OverviewCard>
  );
}

function MembersCard(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const connections = useSelector((state: RootState) => state.connections);
  const fractionVerafied =
    connections.length > 0
      ? connections.filter(isConnectionVerafied).length / connections.length
      : 0;

  return (
    <OverviewCard
      icon={TbUsers}
      title={t('totalMembers')}
      className="min-h-64 flex-1"
      mainFigure={connections.length}
      seeMoreText={t('seeMore')}
      seeMoreLinkProps={{ to: '/contacts' }}>
      <ProgressBar progress={fractionVerafied * 100} variant="primary-light" showText={false} />
      <p className="text-gray-500">
        {t('percentageVerafied', { percentage: Math.round(fractionVerafied * 100) })}
      </p>
    </OverviewCard>
  );
}

function AttendanceCard(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const events = useEvents(EventViewType.PAST_REGULAR);
  const attendance =
    events.length > 0
      ? events.flatMap((e) => e.participants).filter((p) => p.answer === 'YES').length /
        events.flatMap((e) => e.participants).length
      : 0;

  return (
    <OverviewCard
      icon={TbUserCheck}
      title={t('attendance')}
      className="min-h-64 flex-1"
      seeMoreText={t('seeMore')}
      seeMoreLinkProps={{ to: '/events/report' }}>
      <ResponsiveContainer
        width="100%"
        height={200}
        maxHeight={200}
        className="mb-[-100px] mt-[-20px]">
        <PieChart>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={[
              { name: 'Attended', value: attendance, fill: COLOR_PRIMARY_300 },
              {
                name: 'Not attended',
                value: 1 - attendance,
                fill: COLOR_SECONDARY_200,
              },
            ]}
            strokeWidth={0}
            cx="50%"
            cy="50%"
            innerRadius="50%">
            <Label
              value={`${Math.round(attendance * 100)}%`}
              position="center"
              fontSize="26"
              dy={-20}
              fill={COLOR_PRIMARY_900}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <p className="mt-2 text-gray-500">
        {t('averageAttendanceOfEvents', { count: events.length })}
      </p>
    </OverviewCard>
  );
}

function EventsCard() {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const events = useEvents(EventViewType.REGULAR);

  return (
    <OverviewCard
      icon={TbCalendar}
      title={t('upcomingEvents')}
      totalCount={events.length}
      className="max-h-fit flex-1"
      createLinkProps={{ to: '/events/create' }}
      seeMoreText={t('seeAllEvents')}
      seeMoreLinkProps={{ to: '/events' }}>
      <div className="flex flex-col gap-2">
        {events.length === 0 && <EmptyScreenView title="" subtitle={t('noEvents')} />}
        {events.slice(0, 3).map((event) => (
          <EventItem event={event} key={event.id} />
        ))}
      </div>
    </OverviewCard>
  );
}

function EventItem({ event }: { event: Event }) {
  const { title, picture, startTime, participants } = event;

  return (
    <Link to="/events/details" state={{ eventId: event.id }} className="group">
      <div className="flex items-center justify-between gap-2 rounded-xl bg-secondary-200 p-3">
        <div className="flex items-center gap-2">
          <Avatar
            src={picture}
            alias={title.toUpperCase().split(' ').slice(0, 2).join('+')}
            className="text-xl"
          />
          <div>
            <h4>{title}</h4>
            <p className="text-sm text-gray-500">{formatDateAndTime(startTime)}</p>
          </div>
        </div>
        <div className="flex items-center gap-1">
          <TbUserCheck className="text-2xl text-primary-300" />
          <p className="text-primary-300">
            {participants.filter((p) => p.answer === 'YES').length}
          </p>
          <TbChevronRight className="ml-2 text-2xl transition-opacity duration-200 group-hover:opacity-60" />
        </div>
      </div>
    </Link>
  );
}

function TasksCard() {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const tasks = useTasks();

  return (
    <OverviewCard
      icon={TbCircleCheck}
      title={t('toDoList')}
      className="max-h-fit flex-1"
      seeMoreText={t('seeAllTasks')}
      seeMoreLinkProps={{ to: '/lists/task' }}
      totalCount={tasks.length}
      createLinkProps={{ to: '/lists/task', state: { createOpen: true } }}>
      <div className="flex flex-col">
        {tasks.length === 0 && <EmptyScreenView title="" subtitle={t('noTasks')} />}
        {tasks.slice(0, 5).map((task) => (
          <div className="border-b border-b-gray-400 py-3 last-of-type:border-none">
            <div className="flex items-center gap-4">
              <button
                type="button"
                onClick={() => taskService.toggleDone(task)}
                className="justify-centre flex items-center text-sm text-primary-300">
                <div className="flex items-center justify-center gap-2">
                  {task.done ? <FaCircleCheck size={24} /> : <FaRegCircle size={24} />}
                </div>
              </button>
              <h4 className="overflow-x-hidden overflow-ellipsis whitespace-nowrap font-semibold">
                {task.description}
              </h4>
            </div>
            <p className="pl-10 pt-1 text-sm text-gray-500">
              {task.dueDate && formatDateAndTime(new Date(task.dueDate))}
            </p>
          </div>
        ))}
      </div>
    </OverviewCard>
  );
}

function SetupCard() {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const business = useSelectedBusiness();
  const connections = useSelector((state: RootState) => state.connections);
  const allEvents = useEvents(EventViewType.ALL_REGULAR);

  const [hasInviteFields, setHasInviteFields] = useState(false);
  const [hasFiveMembers, setHasFiveMembers] = useState(false);
  const [hasAnEvent, setHasAnEvent] = useState(false);

  useEffect(() => {
    setHasInviteFields(connections.length >= 1);
    setHasFiveMembers(connections.length >= 5);
    setHasAnEvent(allEvents.length > 0);
  }, [allEvents, business?.businessId, connections]);

  return (
    <OverviewCard
      icon={TbSettingsCheck}
      title={t('finishSetup')}
      className="max-h-fit flex-1"
      totalCount={[hasAnEvent, hasFiveMembers, hasInviteFields].filter((x) => !x).length}>
      <div className="flex flex-col gap-2">
        {!hasInviteFields && (
          <OnboardingItem
            title={t('inviteFieldsMissingTitle')}
            subtitle={t('inviteFieldsMissingSubtitle')}
            linkText={t('start')}
            linkProps={{ to: '/invite-link', state: { view: 'SETUP' } }}
          />
        )}
        {!hasFiveMembers && (
          <OnboardingItem
            title={t('notEnoughMembersTitle')}
            subtitle={t('notEnoughMembersSubtitle')}
            linkText={t('continue')}
            linkProps={{ to: '/contacts' }}
          />
        )}
        {!hasAnEvent && (
          <OnboardingItem
            title={t('eventMissingTitle')}
            subtitle={t('eventMissingSubtitle')}
            linkText={t('start')}
            linkProps={{ to: '/events/create' }}
          />
        )}
      </div>
    </OverviewCard>
  );
}

function OnboardingItem({
  title,
  subtitle,
  linkText,
  linkProps: { className: linkClassName, ...linkProps },
}: {
  title: string;
  subtitle: string;
  linkText: string;
  linkProps: Omit<LinkProps, 'children'>;
}) {
  return (
    <div className="flex items-center justify-between gap-4 rounded-xl bg-secondary-50 p-4">
      <div className="flex-1">
        <h4 className="font-semibold">{title}</h4>
        <p className="text-sm text-gray-500">{subtitle}</p>
      </div>
      <Link
        className={twMerge(
          'rounded-xl bg-primary-900 p-2 text-secondary-50 hover:opacity-80',
          linkClassName,
        )}
        {...linkProps}>
        {linkText}
      </Link>
    </div>
  );
}

import { Link } from 'react-router-dom';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type ContactCardProps = {
  veraType: 'vera_smile' | 'vera_sad';
  title: string;
  subtitle: string;
  ctaText: string;
  href: string;
  containerClassName?: string;
};

export default function ContactCard({
  veraType,
  title,
  subtitle,
  ctaText,
  href,
  containerClassName,
}: ContactCardProps): JSX.Element {
  return (
    <div
      className={twMerge(
        'flex items-center gap-4 rounded-xl bg-secondary-200 bg-opacity-50 p-2',
        containerClassName,
      )}>
      <div className="flex aspect-square h-16 w-16 items-center justify-center rounded-xl bg-secondary p-2">
        <img
          src={`${process.env.PUBLIC_URL}/assets/${veraType}.svg`}
          alt="Verified"
          className="w-9"
        />
      </div>
      <div>
        <h4 className="text-lg font-semibold">{title}</h4>
        <p className="text-sm text-gray-500">
          {subtitle}{' '}
          <Link className="text-secondary underline" to={href}>
            {ctaText}
          </Link>
        </p>
      </div>
    </div>
  );
}

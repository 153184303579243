import React, { FormEvent, Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { HiX } from 'react-icons/hi';
import { FaCircleCheck, FaRegCircle, FaPlus, FaUsers } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TbArrowBackUp, TbCheck, TbEdit, TbTrash } from 'react-icons/tb';
import { useLocation } from 'react-router-dom';
import SearchBar from '../../components/forms/SearchBar';
import PageHeader from '../../components/misc/PageHeader';
import Button from '../../components/buttons/Button';
import { taskService } from '../../../services/taskService';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import { Task } from '../../../services/model/taskService.model';
import connectionService from '../../../services/connectionService';
import { Connection, ConnectionType } from '../../../redux/slices/connectionsSlice';
import ModalLayout from '../../components/layouts/ModalLayout';
import Labeled from '../../components/misc/Labeled';
import DatePicker from '../../components/forms/DatePicker';
import { formatDateAndTime } from '../../../utils/stringUtils';
import Input from '../../components/misc/Input';
import Avatar from '../../components/misc/Avatar';
import { RootState } from '../../../redux/reducers';
import useIsScreenSize from '../../../hooks/effects/useIsScreenSize';
import {
  BaseTable,
  BaseTableBody,
  BaseTableDataRow,
  BaseTableHeader,
  BaseTableHeaderRow,
  BaseTableHelperText,
  BaseTableSpacerRow,
} from '../../components/tables/BaseTable';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import EmptyScreenView from '../../components/misc/EmptyScreenView';
import { ActionMenu, ActionMenuButton } from '../../components/menus/ActionMenu';
import Collapsable from '../../components/layouts/Collapsable';

export default function TaskOverviewPage() {
  const business = useSelectedBusiness();
  const { t } = useTranslation('', { keyPrefix: 'page.lists.task' });
  const isMd = useIsScreenSize('md');
  const loc = useLocation();

  const [taskList, setTaskList] = useState<Task[]>([]);
  const [description, setDescription] = useState<string | undefined>();
  const [dueDate, setDueDate] = useState<Date>();
  const [connections, setConnections] = useState<Connection[]>([]);
  const [open, setOpen] = useState(false);
  const [searchTask, setSearchTask] = useState<string>('');
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [visibleTasks, setVisibleTasks] = useState<Task[]>([]);
  const [assignedUsers, setAssignedUsers] = useState<Connection[]>([]);
  const [taskToUpdate, setTaskToUpdate] = useState<Task>();

  useEffect(() => {
    if (loc.state?.createOpen) {
      setOpen(true);
    }

    if (business?.businessId) {
      taskService.getAllTasksForBusiness(business.businessId).then((tasks) => {
        setTaskList(tasks);
        setVisibleTasks(tasks);
      });
      connectionService
        .getConnections()
        .then((x) => x.filter((c) => c.type === ConnectionType.B2C_CONNECTION))
        .then(setConnections);
    }
  }, []);

  useEffect(() => {
    if (taskToUpdate) {
      setTaskToUpdate(visibleTasks.find((x) => x.taskId === taskToUpdate.taskId));
    }
  }, [visibleTasks]);

  useEffect(() => {
    if (!open) {
      setAssignedUsers([]);
      setDueDate(undefined);
      setDescription(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (!updateModalOpen) {
      setDueDate(undefined);
      setDescription(undefined);
    }
  }, [updateModalOpen]);

  const createTask = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (business?.businessId && description && dueDate) {
      taskService
        .createTask(
          business.businessId,
          description,
          dueDate,
          assignedUsers.map((x) => x.userId!),
        )
        .then(() => {
          setOpen(false);
          taskService.getAllTasksForBusiness(business.businessId!).then((tasks) => {
            setTaskList(tasks);
            setVisibleTasks(tasks);
          });
        });
    } else {
      toast.error(t('errorInput'));
    }
  };

  const refreshTable = () => {
    if (business?.businessId) {
      taskService.getAllTasksForBusiness(business.businessId).then(setVisibleTasks);
    }
  };

  const handleSearch = (e: string): void => {
    setSearchTask(e);
    setVisibleTasks(taskList.filter((x) => x.description.toLowerCase().includes(e)));
  };

  return (
    <>
      <PageHeader
        title={t('title')}
        subtitle={t('subtitle')}
        primaryButton={
          <PrimaryButton onClick={() => setOpen(true)} text={t('create')} icon={FaPlus} />
        }
      />
      {taskList.length > 0 && (
        <div className="flex w-full items-center justify-between gap-2 pb-6 max-md:flex-col max-md:items-end">
          <SearchBar
            search={searchTask}
            setSearch={handleSearch}
            inputProps={{
              placeholder: t('search') as string,
              onClick: (e) => e.stopPropagation(),
            }}
            className="md:w-80 lg:w-96"
          />
        </div>
      )}
      <ModalLayout
        closeButton
        open={open}
        setOpen={setOpen}
        className="z-50 mx-6 my-8 w-full rounded-[20px] bg-secondary-50 p-8 sm:max-w-[700px]">
        <div className="text-lg font-semibold text-gray-700">Create task</div>
        <form onSubmit={createTask} className="mt-4 flex w-full flex-col gap-4">
          <Labeled label={t('description')} className="text-sm">
            <Input
              type="text"
              placeholder={t('descriptionPlaceholder')}
              name="name"
              onChange={(e) => setDescription(e.currentTarget.value)}
            />
          </Labeled>
          <div className="flex w-full items-center justify-between gap-6 pb-4 pt-2 max-md:flex-col max-md:items-end">
            <Labeled label={t('dueDate')} className="text-sm">
              <DatePicker date={dueDate} setDate={setDueDate} />
            </Labeled>
            <div className={twMerge('relative flex min-h-10 w-full flex-col')}>
              <Labeled label="Assignee(s)" className="text-sm">
                <AssigneesMenu
                  assignees={assignedUsers.map((x) => ({
                    userId: x.userId!,
                    firstName: x.fields.FIRST_NAME,
                    lastName: x.fields.LAST_NAME,
                  }))}
                  assignMember={(userId) => {
                    const connection = connections.find((c) => c.userId === userId);
                    if (connection) {
                      const connectionExists = assignedUsers.some((a) => a.userId === userId);
                      if (!connectionExists) {
                        setAssignedUsers([...assignedUsers, connection]);
                      }
                    }
                  }}
                  removeMember={(userId) => {
                    setAssignedUsers(assignedUsers.filter((a) => a.userId !== userId));
                  }}
                />
              </Labeled>
            </div>
          </div>
          <Button type="submit" variant="primary" className="self-end">
            {t('create')}
          </Button>
        </form>
      </ModalLayout>
      {taskList.length === 0 ? (
        <EmptyScreenView />
      ) : !isMd ? (
        <div className="flex flex-col gap-6">
          <Collapsable label={t('open')} labelClassName="bg-secondary">
            <TaskTable
              tasks={visibleTasks.filter((x) => !x.done)}
              refreshTable={refreshTable}
              handleUpdate={(task: Task) => {
                setTaskToUpdate(task);
                setUpdateModalOpen(true);
              }}
            />
          </Collapsable>
          <Collapsable collapsed label={t('completed')}>
            <TaskTable
              tasks={visibleTasks.filter((x) => x.done)}
              refreshTable={refreshTable}
              handleUpdate={(task: Task) => {
                setTaskToUpdate(task);
                setUpdateModalOpen(true);
              }}
            />
          </Collapsable>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <Collapsable label={t('open')}>
            {visibleTasks
              .filter((x) => !x.done)
              .map((task) => (
                <TaskCard
                  task={task}
                  key={task.taskId}
                  refreshTable={refreshTable}
                  handleUpdate={() => {
                    setTaskToUpdate(task);
                    setUpdateModalOpen(true);
                  }}
                />
              ))}
          </Collapsable>
          <Collapsable collapsed label={t('completed')}>
            {visibleTasks
              .filter((x) => x.done)
              .map((task) => (
                <TaskCard
                  task={task}
                  key={task.taskId}
                  refreshTable={refreshTable}
                  handleUpdate={() => {
                    setTaskToUpdate(task);
                    setUpdateModalOpen(true);
                  }}
                />
              ))}
          </Collapsable>
        </div>
      )}
      {taskToUpdate && (
        <ModalLayout
          closeButton
          open={updateModalOpen}
          setOpen={setUpdateModalOpen}
          className="z-50 mx-6 my-8 w-full rounded-[20px] bg-secondary-50 p-8 sm:max-w-[700px]">
          <div className="text-lg font-semibold text-gray-700">Edit task</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              taskUtils.updateTask(taskToUpdate.taskId!, description, dueDate, refreshTable);
              setUpdateModalOpen(false);
            }}
            className="mt-4 flex w-full flex-col gap-4">
            <Labeled label={t('description')} className="text-sm">
              <Input
                type="text"
                value={description}
                placeholder={taskToUpdate.description}
                name="name"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  e.stopPropagation();
                  setDescription(e.currentTarget.value);
                }}
              />
            </Labeled>
            <div className="flex w-full items-center justify-between gap-6 pb-4 pt-2 max-md:flex-col max-md:items-end">
              <Labeled label={t('dueDate')} className="text-sm">
                <DatePicker date={dueDate} setDate={setDueDate} />
                <p className="text-xs text-gray-500">
                  {t('previousDueDate')}: {formatDateAndTime(new Date(taskToUpdate.dueDate))}
                </p>
              </Labeled>
              <Labeled label={t('assignees')} className="text-sm">
                <AssigneesMenu
                  taskId={taskToUpdate.taskId}
                  assignees={taskToUpdate.assignees}
                  assignMember={(userId, taskId) =>
                    taskUtils.assignMember(userId, taskId, business?.businessId, refreshTable)
                  }
                  removeMember={(userId, taskId) =>
                    taskUtils.removeMember(userId, taskId, business?.businessId, refreshTable)
                  }
                />
              </Labeled>
            </div>
            <Button type="submit" variant="primary" className="self-end">
              {t('update')}
            </Button>
          </form>
        </ModalLayout>
      )}
    </>
  );
}

function TaskTableRow({
  task,
  refreshTable,
  handleUpdate,
}: {
  task: Task;
  refreshTable: () => void;
  handleUpdate: () => void;
}) {
  const business = useSelectedBusiness();

  return (
    <BaseTableDataRow>
      <td className="w-24">
        <div className="flex items-center">
          <button
            type="button"
            onClick={() => taskUtils.handleMarkDone(task, refreshTable)}
            className="flex items-center justify-center text-primary-300">
            {task.done ? <FaCircleCheck size={24} /> : <FaRegCircle size={24} />}
          </button>
        </div>
      </td>

      <td className="max-w-96">
        <p className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-4">
          {task.description}
        </p>
      </td>
      <td>{formatDateAndTime(new Date(task.dueDate)).replace(', ', '\n')}</td>

      <td>
        <AssigneesMenu
          taskId={task.taskId}
          assignees={task.assignees}
          assignMember={(userId, tId) =>
            taskUtils.assignMember(userId, tId, business?.businessId, refreshTable)
          }
          removeMember={(userId, tId) =>
            taskUtils.removeMember(userId, tId, business?.businessId, refreshTable)
          }
        />
      </td>

      <td>
        <SettingsMenu task={task} handleUpdate={handleUpdate} refreshTable={refreshTable} />
      </td>
    </BaseTableDataRow>
  );
}

function TaskCard({
  task,
  refreshTable,
  handleUpdate,
}: {
  task: Task;
  refreshTable: () => void;
  handleUpdate: () => void;
}) {
  const business = useSelectedBusiness();

  return (
    <div className="flex items-center gap-2">
      <div>
        <Button
          type="button"
          onClick={() => taskUtils.handleMarkDone(task, refreshTable)}
          className="justify-centre mr-2 flex items-center p-0 text-sm text-primary-300">
          <div className="flex items-center justify-center gap-2">
            {task.done ? <FaCircleCheck size={24} /> : <FaRegCircle size={24} />}
          </div>
        </Button>
      </div>

      <div className="flex-1">
        <h4 className="font-semibold">{task.description}</h4>
        <p className="text-sm">{formatDateAndTime(new Date(task.dueDate))}</p>
      </div>

      <AssigneesMenu
        taskId={task.taskId}
        assignees={task.assignees}
        assignMember={(userId, tId) =>
          taskUtils.assignMember(userId, tId, business?.businessId, refreshTable)
        }
        removeMember={(userId, tId) =>
          taskUtils.removeMember(userId, tId, business?.businessId, refreshTable)
        }
      />

      <SettingsMenu task={task} handleUpdate={handleUpdate} refreshTable={refreshTable} />
    </div>
  );
}

function AssigneesMenu({
  taskId,
  assignees,
  assignMember,
  removeMember,
}: {
  taskId?: number;
  assignees: { userId: number; firstName: string; lastName: string }[];
  assignMember: (userId: number, taskId?: number) => void;
  removeMember: (userId: number, taskId?: number) => void;
}) {
  const connections = useSelector((state: RootState) => state.connections).filter(
    (c) => c.type === ConnectionType.B2C_CONNECTION,
  );

  return (
    <Menu as="div" className={twMerge('relative flex min-h-10')}>
      <Menu.Button className="flex items-center gap-[-8px]">
        {assignees.length > 0 ? (
          assignees
            .map((assignee) =>
              connections.find((connection) => connection.userId === assignee.userId),
            )
            .filter(Boolean)
            .slice(0, 2)
            .map((assignee, index) => (
              <Avatar
                className={`relative z-[${10 - index}] flex h-8 w-8 items-center justify-center rounded-full ${index === 0 ? 'bg-secondary-200' : index === 1 ? 'bg-primary-300' : 'bg-primary-900'} text-xs font-bold text-white shadow-md ${index === 0 ? '' : '-ml-2'}`}
                src={assignee?.picture}
                alias={`${assignee?.fields.FIRST_NAME}+${assignee?.fields.LAST_NAME}`}
              />
            ))
        ) : (
          <div className="mb-1 flex h-8 w-8 items-center justify-center rounded-full bg-secondary-200 text-sm text-gray-800">
            <FaPlus />
          </div>
        )}
        {assignees.length > 2 && (
          <div
            className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-blue-900 text-sm font-bold text-white shadow-md"
            style={{
              marginLeft: '-8px',
              zIndex: 1,
            }}>
            +{assignees.length - 2}
          </div>
        )}
      </Menu.Button>
      <AssignUnassignButton
        taskId={taskId}
        assignedUsers={assignees}
        allUsers={connections}
        assignMember={assignMember}
        removeMember={removeMember}
      />
    </Menu>
  );
}

interface AssignUnassignButtonProps {
  taskId?: number;
  assignedUsers: { userId: number; firstName: string; lastName: string }[];
  allUsers: Connection[];
  assignMember: (userId: number, taskId?: number) => void;
  removeMember: (userId: number, taskId?: number) => void;
}

function AssignUnassignButton({
  taskId,
  assignedUsers,
  allUsers,
  assignMember,
  removeMember,
}: AssignUnassignButtonProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('');
  const [visibleUsers, setVisibleUsers] = useState<Connection[]>([]);

  const { t } = useTranslation('', { keyPrefix: 'page.lists.task' });

  useEffect(() => {
    setVisibleUsers(allUsers.filter((x) => !assignedUsers.some((a) => a.userId === x.userId)));
  }, [assignedUsers, allUsers]);

  const handleInputChange = (e: string): void => {
    setInputValue(e);
    setVisibleUsers(
      allUsers.filter(
        (user) =>
          !assignedUsers.some((a) => a.userId === user.userId) &&
          (user.fields.FIRST_NAME.toLowerCase().includes(e) ||
            user.fields.LAST_NAME.toLowerCase().includes(e)),
      ),
    );
  };

  const handleAssign = async (userId: number): Promise<void> => {
    assignMember(userId, taskId);
    setInputValue('');
  };

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95">
      <Menu.Items className="-b-10 absolute right-0 z-50 mt-2 max-h-[350px] w-60 overflow-y-auto rounded-md bg-secondary-50 p-4 shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="bg-secondary-100 mb-1 flex items-center rounded-md text-base text-gray-800">
          <FaUsers className="size-5" />
          <span className="ml-1"> {t('assignees')} </span>
        </div>
        <SearchBar
          search={inputValue}
          setSearch={handleInputChange}
          inputProps={{
            placeholder: t('searchUsers'),
            onClick: (e) => e.stopPropagation(),
          }}
        />
        <div className="mb-3">
          <p className="mb-1 text-xs font-semibold text-gray-600">{t('assignedUsers')} </p>
          <div className="no-scrollbar max-h-[80px] overflow-y-auto">
            {assignedUsers.length === 0 ? (
              <p className="text-xs text-gray-500">{t('noAssignees')} </p>
            ) : (
              assignedUsers.map((user) => (
                <div
                  key={user.userId}
                  className="bg-secondary-100 mb-1 flex items-center justify-between rounded-md px-2 py-1 text-sm text-gray-800">
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                  <Button
                    type="button"
                    onClick={() => removeMember(user.userId!, taskId)}
                    className="text-primary-500 hover:text-primary-700 focus:outline-none">
                    <HiX className="h-4 w-4" />
                  </Button>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <p className="mb-1 text-xs font-semibold text-gray-600">{t('unassignedUsers')} </p>
          <div className="no-scrollbar max-h-[80px] overflow-y-auto">
            {visibleUsers.length === 0 ? (
              <p className="text-xs text-gray-500">{t('noUsersToAssign')} </p>
            ) : (
              visibleUsers.map((user) => (
                <div
                  key={user.userId}
                  className="hover:bg-secondary-100 mb-1 flex items-center justify-between rounded-md bg-secondary-50 px-2 py-1 text-sm text-gray-800">
                  <span>
                    {user.fields.FIRST_NAME} {user.fields.LAST_NAME}
                  </span>
                  <Button
                    type="button"
                    onClick={() => handleAssign(user.userId!)}
                    className="text-primary-500 hover:text-primary-700 focus:outline-none">
                    <FaPlus />
                  </Button>
                </div>
              ))
            )}
          </div>
        </div>
        <Menu.Item as="div" className="bottom-0 right-0 items-end justify-items-end">
          {({ close }) => (
            <Button onClick={close} variant="secondary">
              {t('continue')}
            </Button>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  );
}

function SettingsMenu({
  task,
  handleUpdate,
  refreshTable,
}: {
  task: Task;
  handleUpdate: () => void;
  refreshTable: () => void;
}) {
  const { t } = useTranslation('', { keyPrefix: 'page.lists.task' });

  return (
    <ActionMenu>
      <ActionMenuButton icon={TbEdit} text={t('edit')} onClick={handleUpdate} />
      <ActionMenuButton
        icon={task.done ? TbArrowBackUp : TbCheck}
        text={task.done ? t('reopen') : t('markCompleted')}
        onClick={() => taskUtils.handleMarkDone(task, refreshTable)}
      />
      <ActionMenuButton
        icon={TbTrash}
        className="text-danger"
        text={t('delete')}
        onClick={() => taskUtils.deleteTask(task.taskId!, refreshTable)}
      />
    </ActionMenu>
  );
}

function TaskTable({
  tasks,
  refreshTable,
  handleUpdate,
}: {
  tasks: Task[];
  refreshTable: () => void;
  handleUpdate: (task: Task) => void;
}) {
  const { t } = useTranslation('', { keyPrefix: 'page.lists.task' });

  return (
    <BaseTable>
      <BaseTableHeader>
        <BaseTableHeaderRow>
          <th>{t('status')}</th>
          <th>{t('task')}</th>
          <th>{t('dueDate')}</th>
          <th>{t('assignees')}</th>
          <th>{t('actions')}</th>
        </BaseTableHeaderRow>
      </BaseTableHeader>
      <BaseTableBody>
        <BaseTableSpacerRow />
        {tasks.length === 0 && <BaseTableHelperText>{t('noTasks')}</BaseTableHelperText>}
        {tasks.map((task) => (
          <TaskTableRow
            task={task}
            key={task.taskId}
            refreshTable={refreshTable}
            handleUpdate={() => handleUpdate(task)}
          />
        ))}
      </BaseTableBody>
    </BaseTable>
  );
}

const taskUtils = {
  assignMember: (userId: number, taskId?: number, businessId?: number, cb?: () => void) => {
    if (businessId) {
      taskService.assignMemberToTask(taskId!, userId, businessId).then(cb);
    }
  },
  removeMember: (userId: number, taskId?: number, businessId?: number, cb?: () => void) => {
    if (businessId) {
      taskService.removeMemberFromTask(taskId!, userId, businessId).then(cb);
    }
  },
  updateTask: (taskId: number, description?: string, dueDate?: Date, cb?: () => void) => {
    taskService
      .updateTask(taskId, {
        description,
        dueDate,
      })
      .then(cb);
  },
  deleteTask: (taskId: number, cb?: () => void) => {
    taskService.deleteTask(taskId).then(cb);
  },
  handleMarkDone: (task: Task, cb?: () => void) => {
    taskService.updateTask(task.taskId!, { done: !task.done }).then(cb);
  },
};

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ApiImage from '../misc/ApiImage';
import Icon from '../icons/Icon';
import { businessTypeIcons } from '../../../utils/iconUtils';
import Business from '../../../types/business';
import { associationsRaceService } from '../../../services/associationsRaceService';

export default function BusinessCard({ business }: { business: Business }): JSX.Element {
  const [userCount, setUserCount] = React.useState(0);
  const { t } = useTranslation('', { keyPrefix: 'page.race.association' });

  useEffect(() => {
    if (business.businessId !== undefined && business.businessId !== null) {
      associationsRaceService.getUsersInCommunity(business.businessId).then(setUserCount);
    }
  }, [business.businessId]);

  return (
    <div className="group relative w-72 rounded-lg border border-primary-900 transition-all hover:bg-primary-300 hover:bg-opacity-10">
      {business.picture ? (
        <ApiImage
          alt="business-profile"
          path={business.picture}
          className="h-48 w-full rounded-t-lg object-cover"
        />
      ) : (
        <div className="mx-auto flex h-48 w-full items-center rounded-t-lg text-primary-900">
          <Icon
            className="mx-auto my-auto h-20 w-20 text-primary-900"
            icon={businessTypeIcons[business.type]}
          />
        </div>
      )}
      <div className="wrap flex w-full flex-col flex-wrap justify-between gap-2 border-t border-primary-900 p-4 py-3">
        <p className="w-full overflow-hidden break-words font-medium">{business.name}</p>
        <p className="overflow-hidden break-words font-medium">
          {t('userCount')}: {userCount}
        </p>
        <p className="" />
      </div>
    </div>
  );
}

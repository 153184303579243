import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LuListPlus, LuListRestart } from 'react-icons/lu';
import { toast } from 'react-toastify';
import ModalLayout from '../layouts/ModalLayout';
import IconBadge from '../icons/IconBadge';
import Labeled from '../misc/Labeled';
import Button from '../buttons/Button';
import ConnectionsList from '../misc/ConnectionsList';
import { ConnectionsListConnection } from '../../../types/misc';
import TabButtons from '../buttons/TabButtons';

interface ScoreCardModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (data: ScoreCardFormData) => Promise<void>;
  onClosed?: () => void;
  initialState?: ScoreCardFormData;
  membersOfBusiness: ConnectionsListConnection[];
}

type ScoreCardFormData = {
  name: string;
  memberEditAccess: boolean;
  containsAllMembers?: boolean;
  selectedUserIds?: number[];
};

export default function ScoreCardModal({
  open,
  setOpen,
  onSubmit,
  initialState,
  onClosed,
  membersOfBusiness,
}: ScoreCardModalProps): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.lists.score.forms' });
  const { t: errorT } = useTranslation('', { keyPrefix: 'page.lists.score.error' });
  const [scoreList, setScoreList] = React.useState<ScoreCardFormData>({
    name: '',
    memberEditAccess: false,
  });
  const [selectedMembersIdsList, setSelectedMembersIdsList] = React.useState<number[]>([]);

  useEffect(() => {
    setScoreList((prev) => ({
      ...prev,
      selectedUserIds: selectedMembersIdsList,
    }));
  }, [selectedMembersIdsList]);

  useEffect(() => {
    if (initialState) {
      setScoreList({
        ...initialState,
        selectedUserIds: selectedMembersIdsList,
      });
    } else {
      setScoreList({
        name: '',
        memberEditAccess: false,
        containsAllMembers: true,
      });
    }
    setSelectedMembersIdsList([]);
  }, [initialState]);

  useEffect(() => {
    if (!open && onClosed) {
      onClosed();
    }

    if (!open && !initialState) {
      setScoreList({
        name: '',
        memberEditAccess: false,
        containsAllMembers: true,
      });
    }
    setSelectedMembersIdsList([]);
  }, [open, onClosed]);

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="z-50 w-full max-w-[600px] rounded-xl bg-secondary-50 p-6">
      <div className="flex items-center gap-4 border-b border-secondary-200 pb-2">
        <IconBadge icon={initialState ? LuListRestart : LuListPlus} />
        <h2 className="text-lg font-semibold">{t('createTitle')}</h2>
      </div>
      <div className="flex flex-col gap-4 pt-2">
        <Labeled label={t('name')}>
          <input
            value={scoreList.name}
            onChange={(ev) => setScoreList((prev) => ({ ...prev, name: ev.target.value }))}
            type="text"
            placeholder={t('namePlaceholder')}
          />
        </Labeled>

        <TabButtons
          initialState={scoreList.memberEditAccess}
          containerClassName="bg-secondary-200"
          highlightClassName="bg-secondary-50"
          buttonClassName="px-2"
          buttonSelectedClassName="px-2"
          options={[
            { label: t('membersCannotEdit'), value: false as const },
            { label: t('membersCanEdit'), value: true as const },
          ]}
          onTabChange={(value) => {
            setScoreList((prev) => ({
              ...prev,
              memberEditAccess: value,
            }));
          }}
        />
        <TabButtons
          initialState={scoreList.containsAllMembers}
          containerClassName="bg-secondary-200"
          highlightClassName="bg-secondary-50"
          buttonClassName="px-2"
          buttonSelectedClassName="px-2"
          options={[
            { label: t('containsAllMembers'), value: true as const },
            { label: t('selectMembers'), value: false as const },
          ]}
          onTabChange={(value) => {
            setScoreList((prev) => ({
              ...prev,
              containsAllMembers: value,
            }));
          }}
        />
        {!scoreList.containsAllMembers && (
          <ConnectionsList
            connections={membersOfBusiness}
            selected={selectedMembersIdsList}
            hasSearch={false}
            setSelected={setSelectedMembersIdsList}
            handleSelectFunction={undefined}
            handleDiselectFunction={undefined}
          />
        )}
        <Button
          variant="primary"
          className="self-end"
          onClick={() => {
            onSubmit(scoreList)
              .then(() => {
                setOpen(false);
              })
              .catch((err: Error) => {
                if (err.message === 'STOP') {
                  toast.error(errorT('emptyNameError'));
                }
              });
          }}>
          {t('createButton')}
        </Button>
      </div>
    </ModalLayout>
  );
}

import { twMerge } from 'tailwind-merge';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AiOutlinePlus } from 'react-icons/ai';
import { Discount } from '../../../types/misc';
import Button from '../buttons/Button';
import orderService from '../../../services/orderService';

interface DiscountInputProps {
  discount: Discount | undefined;
  setDiscount: (discount: Discount | undefined) => void;
  className?: string;
  inputClassName?: string;
}

export default function DiscountInput({
  discount,
  setDiscount,
  className = '',
  inputClassName = '',
}: DiscountInputProps): JSX.Element {
  const [code, setCode] = useState<string>('');
  const { t } = useTranslation();

  const handleSubmit = () => {
    orderService
      .getDiscount(code)
      .then((amount) => {
        setDiscount({ code, amount });
        toast.success(t('toast.success.codeApplied'));
      })
      .catch(() => {
        toast.error(t('toast.error.codeInvalid'));
      })
      .finally(() => {
        setCode('');
      });
  };

  return (
    <div className={twMerge('flex flex-col gap-2', className)}>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between gap-2">
          <label htmlFor="discount" className="mb-1 pr-2 text-[15px] font-medium">
            {t('component.discount.code')}
          </label>
          {discount && (
            <div className="flex items-center justify-center gap-2 rounded-full bg-primary-300 px-3 py-1 text-sm text-secondary-50">
              {`${discount.code}: ${discount.amount * 100}%`}
              <AiOutlinePlus
                className="icon h-5 w-5 rotate-45 cursor-pointer text-secondary-50"
                onClick={() => setDiscount(undefined)}
              />
            </div>
          )}
        </div>

        <input
          type="text"
          className={inputClassName}
          onChange={(e) => setCode(e.target.value)}
          value={code}
        />
      </div>

      <Button variant="primary" className="ml-auto" onClick={handleSubmit}>
        {t('component.discount.button')}
      </Button>
    </div>
  );
}

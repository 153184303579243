import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiTrash } from 'react-icons/hi';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FaTicket } from 'react-icons/fa6';
import Toggle from '../misc/Toggle';
import {
  BusinessInvite,
  BusinessInvitePropertyType,
  CustomField,
  CustomFieldType,
  CustomLabelField,
  CustomMultipleChoiceField,
} from '../../../services/model/inviteService.model';
import { filterCustomLabelFields } from '../../../utils/filterUtils';
import QuestionCircle from '../misc/QuestionCircle';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import { businessInvitePropertyTypeIcons } from '../../../utils/iconUtils';
import Button from '../buttons/Button';
import { isCommunity, isMoments } from '../../../constants';
import { RequiredFields } from '../../../types/misc';

interface InviteFormProps {
  invite: BusinessInvite;
  setInvite: (invite: BusinessInvite) => void;
}

export default function InviteForm({ invite, setInvite }: InviteFormProps): JSX.Element {
  const { optionalFields, mandatoryFields, customFields, nonRequestedFields } = invite;
  const selectedFields = [...optionalFields, ...mandatoryFields];

  const { t } = useTranslation();

  const basicFields: RequiredFields<BasicFieldType, 'type'>[] = [
    {
      field: t('dataType.phoneNumber'),
      type: BusinessInvitePropertyType.PHONENUMBER,
    },
    {
      field: t('dataType.email'),
      type: BusinessInvitePropertyType.EMAIL,
    },
    {
      field: t('dataType.address'),
      type: BusinessInvitePropertyType.ADDRESS,
    },
    {
      field: t('dataType.birthDate'),
      type: BusinessInvitePropertyType.BIRTHDATE,
    },
    {
      field: t('dataType.businessName'),
      type: BusinessInvitePropertyType.BUSINESSNAME,
    },
    // ...Object.values(SocialType).map((type) => ({ field: type, type })),
  ];

  const handleBasicFieldSelect = (field: BusinessInvitePropertyType): void => {
    if (selectedFields.includes(field)) {
      setInvite({
        ...invite,
        optionalFields: optionalFields.filter((f): boolean => f !== field),
        mandatoryFields: mandatoryFields.filter((f): boolean => f !== field),
        nonRequestedFields: [...nonRequestedFields, field],
      });
    } else {
      setInvite({
        ...invite,
        optionalFields: [...optionalFields, field],
        nonRequestedFields: nonRequestedFields.filter((f): boolean => f !== field),
      });
    }
  };

  const handleMandatoryBasicFieldSelect = (field: BusinessInvitePropertyType): void => {
    if (mandatoryFields.includes(field)) {
      setInvite({
        ...invite,
        mandatoryFields: mandatoryFields.filter((f): boolean => f !== field),
        optionalFields: [...optionalFields, field],
      });
    } else {
      setInvite({
        ...invite,
        mandatoryFields: [...mandatoryFields, field],
        optionalFields: optionalFields.filter((f): boolean => f !== field),
        nonRequestedFields: nonRequestedFields.filter((f): boolean => f !== field),
      });
    }
  };

  const handleCommunicationNameSelect = (): void => {
    if (customFields.find((x) => x.type === CustomFieldType.COMMUNICATION_NAME)) {
      setInvite({
        ...invite,
        customFields: customFields.filter((x) => x.type !== CustomFieldType.COMMUNICATION_NAME),
      });
    } else {
      setInvite({
        ...invite,
        customFields: [
          ...customFields,
          {
            type: CustomFieldType.COMMUNICATION_NAME,
            mandatory: false,
          },
        ],
      });
    }
  };

  const handleMandatoryCommunicationNameSelect = (): void => {
    const field = customFields.find((x) => x.type === CustomFieldType.COMMUNICATION_NAME);
    if (!field) {
      setInvite({
        ...invite,
        customFields: [
          ...customFields,
          {
            type: CustomFieldType.COMMUNICATION_NAME,
            mandatory: true,
          },
        ],
      });
    } else {
      setInvite({
        ...invite,
        customFields: customFields.map((x) =>
          x.type === CustomFieldType.COMMUNICATION_NAME
            ? { ...field, mandatory: !field.mandatory }
            : x,
        ),
      });
    }
  };

  const handleCustomFieldAdd = (): void => {
    setInvite({
      ...invite,
      customFields: [
        ...invite.customFields,
        {
          type: CustomFieldType.TEXT,
          label: '',
          mandatory: false,
        },
      ],
    });
  };
  return (
    <div className="h-a z-10 w-full rounded-lg">
      {/* Basic fields */}
      <div className="">
        <div className="flex items-center justify-between">
          <p className="text-lg font-medium">{t('form.invite.basic.title')}</p>
          <p className="text-[11px]">{t('form.invite.mandatory')}</p>
        </div>

        <div className="relative flex w-full flex-col">
          {isMoments && (
            <BasicField
              field={{
                field: t('dataType.communicationName'),
                icon: <FaTicket className="h-5 w-5" aria-hidden="true" />,
              }}
              selected={!!customFields.find((x) => x.type === CustomFieldType.COMMUNICATION_NAME)}
              setSelected={handleCommunicationNameSelect}
              mandatory={
                !!customFields.find((x) => x.type === CustomFieldType.COMMUNICATION_NAME)?.mandatory
              }
              setMandatory={handleMandatoryCommunicationNameSelect}>
              <QuestionCircle className="w-[250px]">
                {t('form.invite.basic.communicationNameExplanation')}
              </QuestionCircle>
            </BasicField>
          )}
          {basicFields.map((field) => (
            <BasicField
              selected={selectedFields.includes(field.type)}
              setSelected={() => handleBasicFieldSelect(field.type)}
              mandatory={mandatoryFields.includes(field.type)}
              setMandatory={() => handleMandatoryBasicFieldSelect(field.type)}
              field={field}
            />
          ))}
          <div className="absolute right-16 top-0 h-full -translate-x-1/2 transform border-l border-secondary-200" />
        </div>
      </div>

      {/* Custom fields */}
      {isCommunity && (
        <div className="mt-6 border-t border-secondary-200 pt-4 lg:mb-0">
          <p className="mt-2 text-lg font-medium">{t('form.invite.custom.title')}</p>
          {filterCustomLabelFields(customFields).length > 0 && (
            <div className="mb-2 mt-2 flex items-center justify-between">
              <div className="flex items-center">
                <p className="invisible mr-[110px] text-[13px] sm:visible">
                  {t('form.invite.custom.typeQuestion')}
                </p>
                <p className="invisible text-[13px] sm:visible">
                  {t('form.invite.custom.question')}
                </p>
              </div>
              <p className="text-[11px]">{t('form.invite.mandatory')}</p>
            </div>
          )}
          <div className="relative">
            <CustomFields
              fields={customFields}
              setFields={(fields: CustomField[]): void =>
                setInvite({ ...invite, customFields: fields })
              }
            />
            <div className="absolute right-16 top-0 h-full -translate-x-1/2 transform border-l border-secondary-200" />
          </div>

          <Button variant="tertiary" onClick={handleCustomFieldAdd} className="mb-6 mt-4">
            <AiOutlinePlus className="mr-2 h-5 w-5 stroke-2 text-black" />
            <p className="font-medium text-black">{t('general.add')}</p>
          </Button>
        </div>
      )}

      {/* Automatic responses */}
      {/* <div>
        <p className="text-lg font-medium">Wil je automatisch reacties accepteren?</p>
        <div className="flex items-center mt-2">
          <Toggle
            className="mr-4"
            state={acceptAutomatically}
            handleToggle={() => setInvite({ ...invite, acceptAutomatically: !acceptAutomatically })}
          />
          {acceptAutomatically ? 'Yes' : 'No'}
        </div>
      </div> */}
    </div>
  );
}

type BasicFieldType = { field: string; icon?: JSX.Element; type?: BusinessInvitePropertyType };

interface BasicFieldProps {
  selected: boolean;
  setSelected: () => void;
  mandatory: boolean;
  setMandatory: () => void;
  field: BasicFieldType;
  children?: React.ReactNode;
}

function BasicField({
  selected,
  setSelected,
  mandatory,
  setMandatory,
  field,
  children,
}: BasicFieldProps): JSX.Element {
  return (
    <div key={field.field} className="flex items-center justify-between">
      <div
        className={classNames(
          'my-1 mr-6 flex w-full cursor-pointer items-center justify-between rounded-lg px-2 py-[3px] transition-all',
          {
            'bg-secondary-200': selected,
          },
        )}
        onClick={setSelected}>
        <div className="flex w-full items-center gap-2">
          <div className="flex items-center justify-center rounded-[6px] bg-secondary p-1 text-secondary-50">
            {field.type
              ? businessInvitePropertyTypeIcons[
                  field.type as keyof typeof businessInvitePropertyTypeIcons
                ]
              : field.icon}
          </div>
          <p className="whitespace-nowrap py-2 text-sm">{capitalizeFirstLetter(field.field)}</p>
          {children}
        </div>
        <input
          type="checkbox"
          checked={selected}
          className="m-1 rounded-full border-secondary-200"
        />
      </div>
      <div>
        <Toggle className="mr-4" state={mandatory} handleToggle={setMandatory} />
      </div>
    </div>
  );
}

interface CustomFieldsProps {
  fields: CustomField[];
  setFields: (fields: CustomField[]) => void;
}

function CustomFields({ fields, setFields }: CustomFieldsProps): JSX.Element {
  const { t } = useTranslation();

  const handleMandatoryCustomFieldSelect = (index: number): void => {
    const newCustomFields = [...fields];
    const updatedCustomField = { ...newCustomFields[index] };
    updatedCustomField.mandatory = !updatedCustomField.mandatory;
    newCustomFields[index] = updatedCustomField;
    setFields(newCustomFields);
  };

  const handleCustomFieldLabelChange = (index: number, value: string): void => {
    const newCustomFields = [...fields];
    const updatedCustomField = { ...newCustomFields[index] };
    (updatedCustomField as CustomLabelField).label = value;
    newCustomFields[index] = updatedCustomField;
    setFields(newCustomFields);
  };

  const handleCustomFieldTypeChange = (index: number, value: CustomFieldType): void => {
    const newCustomFields = [...fields];
    const field = newCustomFields[index] as CustomLabelField;
    if (value === CustomFieldType.TEXT) {
      newCustomFields[index] = {
        type: CustomFieldType.TEXT,
        label: field.label,
        mandatory: field.mandatory,
      };
    } else {
      newCustomFields[index] = {
        type: CustomFieldType.MULTIPLE_CHOICE,
        label: field.label,
        options: ['', ''],
        mandatory: field.mandatory,
      };
    }
    setFields(newCustomFields);
  };

  const handleCustomFieldDelete = (index: number): void => {
    const newCustomFields = [...fields];
    newCustomFields.splice(index, 1);
    setFields(newCustomFields);
  };

  const handleMultipleChoiceOptionAdd = (index: number): void => {
    const newCustomFields = [...fields];
    newCustomFields[index] = {
      ...newCustomFields[index],
      options: [...(newCustomFields[index] as CustomMultipleChoiceField).options, ''],
    } as CustomMultipleChoiceField;
    setFields(newCustomFields);
  };

  const handleMultipleChoiceOptionChange = (
    index: number,
    optionIndex: number,
    value: string,
  ): void => {
    const newCustomFields = [...fields];
    const newField = {
      ...newCustomFields[index],
      options: [...(fields[index] as CustomMultipleChoiceField).options],
    };
    newField.options[optionIndex] = value;
    newCustomFields[index] = newField;
    setFields(newCustomFields);
  };

  const handleMultipleChoiceOptionDelete = (index: number, optionIndex: number): void => {
    const newCustomFields = [...fields];
    const newField = {
      ...newCustomFields[index],
      options: [...(fields[index] as CustomMultipleChoiceField).options],
    };
    newField.options.splice(optionIndex, 1);
    newCustomFields[index] = newField;
    setFields(newCustomFields);
  };

  const renderField = (field: CustomField, fieldIndex: number): JSX.Element => {
    switch (field.type) {
      case CustomFieldType.TEXT:
        return (
          <div className="relative mb-10 flex items-start sm:mb-0 sm:mt-0 sm:items-center">
            {/* Select question type */}
            <p className="absolute -top-6 left-3 text-[13px] sm:hidden">
              {t('form.invite.custom.type')}
            </p>
            <div className="relative flex w-full flex-col items-center sm:mt-2 sm:flex-row">
              <select
                value={field.type}
                onChange={(e) =>
                  handleCustomFieldTypeChange(fieldIndex, e.target.value as CustomFieldType)
                }
                className="ml-4 h-[50px] w-full flex-grow-0 rounded-[15px] border-secondary-200 border-opacity-50 bg-secondary-200 bg-opacity-50 font-medium text-primary-900 transition-colors focus:border-primary-300 focus:border-opacity-100 sm:ml-0 sm:mt-0 sm:w-fit">
                <option value="TEXT" className="active:bg-secondary-200">
                  Text
                </option>
                <option value="MULTIPLE_CHOICE" className="active:bg-secondary-200">
                  Multiple choice
                </option>
              </select>
              {/* Label input */}
              <p className="absolute left-3 top-[50px] text-[13px] sm:hidden">
                {t('form.invite.custom.question')}
              </p>
              <div className="relative mt-8 flex w-full sm:mt-0">
                <input
                  type="text"
                  value={field.label}
                  onChange={(e) => handleCustomFieldLabelChange(fieldIndex, e.target.value)}
                  className="-mr-2 ml-2 w-full rounded-lg border-secondary-200 sm:mr-0"
                />
                <button
                  onClick={() => handleCustomFieldDelete(fieldIndex)}
                  className="absolute -right-16 bottom-2 w-fit sm:static sm:ml-2"
                  type="button">
                  <HiTrash className="h-5 w-5 text-primary" />
                </button>
              </div>
            </div>

            {/* Delete field and mandatory slider */}

            <div className="mx-4 -mt-2 h-1 w-1 border-r" />
            <div className="mt-[10px] flex items-center sm:mt-0">
              <Toggle
                className="mr-4"
                state={field.mandatory}
                handleToggle={() => handleMandatoryCustomFieldSelect(fieldIndex)}
              />
            </div>
          </div>
        );
      case CustomFieldType.MULTIPLE_CHOICE:
        return (
          <div className="relative -mt-[10px] mb-10 flex h-auto items-start sm:mb-0 sm:mt-0">
            <p className="absolute -top-[14px] left-3 text-[13px] sm:hidden">
              {t('form.invite.custom.type')}
            </p>
            <div className="flex w-full flex-col items-start sm:flex-row">
              <div className="mt-2 flex w-full flex-col justify-end justify-items-end sm:w-fit">
                {/* Select type */}
                <select
                  value={field.type}
                  onChange={(e) =>
                    handleCustomFieldTypeChange(fieldIndex, e.target.value as CustomFieldType)
                  }
                  className="ml-2 h-[50px] w-full flex-grow-0 rounded-[15px] border-secondary-200 border-opacity-50 bg-secondary-200 bg-opacity-50 font-medium text-primary-900 transition-colors focus:border-primary-300 focus:border-opacity-100 sm:ml-0 sm:mt-0 sm:w-fit">
                  <option value="TEXT">Text</option>
                  <option value="MULTIPLE_CHOICE">Multiple choice</option>
                </select>
                <p className="invisible mx-0 mt-0 flex justify-end text-[13px] sm:visible sm:mx-2 sm:mt-5">
                  {t('form.invite.custom.options')}:
                </p>
              </div>

              <div className="-mt-2 mr-2 flex w-full flex-col gap-2 sm:mr-0 sm:mt-0">
                {/* Label */}
                <p className="-mb-1 ml-3 text-[13px] sm:hidden">
                  {t('form.invite.custom.question')}
                </p>
                <div className="relative flex w-full sm:mt-2">
                  <input
                    type="text"
                    value={field.label}
                    onChange={(e) => handleCustomFieldLabelChange(fieldIndex, e.target.value)}
                    className="-mr-2 ml-2 w-full rounded-lg border-secondary-200 sm:mr-0"
                  />
                  <button
                    onClick={() => handleCustomFieldDelete(fieldIndex)}
                    className="absolute -right-[65px] bottom-[9px] ml-2 w-fit sm:static"
                    type="button">
                    <HiTrash className="h-5 w-5 text-primary" />
                  </button>
                </div>
                <p className="-mb-1 ml-3 text-[13px] sm:hidden">
                  {t('form.invite.custom.options')}:
                </p>
                {/* Multiple choice options */}
                {(field as CustomMultipleChoiceField).options.map((option, optionIndex) => (
                  <div className="relative sm:mr-9">
                    <input
                      type="text"
                      onChange={(e) =>
                        handleMultipleChoiceOptionChange(fieldIndex, optionIndex, e.target.value)
                      }
                      value={option}
                      className="ml-2 w-full rounded-lg border-secondary-200"
                    />
                    {/* Delete option button */}
                    {optionIndex > 1 && (
                      <button
                        onClick={() => handleMultipleChoiceOptionDelete(fieldIndex, optionIndex)}
                        type="button"
                        className="absolute inset-y-0 right-0 flex items-center px-2">
                        <AiOutlinePlus className="h-5 w-5 rotate-45 text-gray-400 text-primary-300" />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Delete field and mandatory slider */}
            <div className="mt-3 h-full flex-col justify-between">
              <div className="flex h-full items-start py-2">
                <div className="h-18 mx-4 -mt-2 w-1 border-r border-secondary-200" />
                <div className="flex items-center">
                  <Toggle
                    className="mr-4"
                    state={field.mandatory}
                    handleToggle={() => handleMandatoryCustomFieldSelect(fieldIndex)}
                  />
                </div>
              </div>
            </div>
            {/* Add option button */}
            <button
              onClick={() => handleMultipleChoiceOptionAdd(fieldIndex)}
              className="absolute bottom-2 right-4 ml-2 w-fit sm:bottom-[10px] sm:right-[90px]"
              type="button">
              <AiOutlinePlus className="h-5 w-5 stroke-2" />
            </button>
          </div>
        );
      default:
        return <></>;
    }
  };

  return <>{fields.map(renderField)}</>;
}

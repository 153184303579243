import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCalendar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { FaArrowRight } from 'react-icons/fa6';
import _ from 'lodash';
import { PropsOf } from '@headlessui/react/dist/types';
import { twMerge } from 'tailwind-merge';
import useEvents from '../../../hooks/business/useEvents';
import { Event, EventViewType } from '../../../types/event';
import eventService from '../../../services/eventService';
import PageHeader from '../../components/misc/PageHeader';
import SearchBar from '../../components/forms/SearchBar';
import { ParticipationAnswersOverview } from './EventDetailsPage';
import { formatDate, formatDateAndTime } from '../../../utils/stringUtils';
import useIsScreenSize from '../../../hooks/effects/useIsScreenSize';
import { filterBySearch } from '../../../utils/filterUtils';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import {
  BaseTable,
  BaseTableBody,
  BaseTableDataRow,
  BaseTableHeader,
  BaseTableHeaderRow,
  BaseTablePagination,
  BaseTableSpacerRow,
} from '../../components/tables/BaseTable';
import PrimaryLinkButton from '../../components/buttons/PrimaryLinkButton';
import EmptyScreenView from '../../components/misc/EmptyScreenView';
import { Pagination, usePagination } from '../../../hooks/effects/usePagination';
import DatePicker from '../../components/forms/DatePicker';
import dateUtils from '../../../utils/dateUtils';

export default function EventsOverviewPage(): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const business = useSelectedBusiness();
  const allEvents = useEvents(EventViewType.ALL_REGULAR);
  const isMd = useIsScreenSize('md');
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(
    new Date(Date.now() + 1000 * 60 * 60 * 24 * 30 * 3),
  ); // 3 months

  const filteredEvents = useMemo(() => {
    return filterBySearch(allEvents, search, 'title', 'description').filter((e) =>
      dateUtils.isInBounds(e.startTime, startDate, endDate),
    );
  }, [allEvents, search, startDate, endDate]);

  const { items: eventsPerPage, ...pagination } = usePagination(filteredEvents);

  useEffect(() => {
    eventService.getUpcomingEvents().then(() => eventService.prependPastEvents());
  }, [business]);

  useEffect(() => {
    pagination.setPage(1);
  }, [startDate, endDate]);

  return (
    <>
      <PageHeader
        title={t(`page.event.regular.title`)}
        subtitle={t(`page.event.regular.subtitle`)}
        primaryButton={
          <PrimaryLinkButton
            to="/events/create"
            text={t(`page.event.regular.add`)}
            icon={FaCalendar}
          />
        }
      />
      <div className="flex flex-1 flex-col gap-5 pb-4">
        <div className="flex flex-wrap items-center justify-between gap-4 pb-2">
          <SearchBar
            inputProps={{ placeholder: t('page.event.regular.searchEvents') }}
            className="max-w-[380px]"
            search={search}
            setSearch={setSearch}
          />
          <div className="flex items-center gap-1">
            <DatePicker
              customFormat={`[${t('general.from')}:] DD-MM-YYYY`}
              date={startDate}
              setDate={setStartDate}
              includeTime={false}
            />
            <DatePicker
              customFormat={`[${t('general.to')}:] DD-MM-YYYY`}
              date={endDate}
              setDate={setEndDate}
              includeTime={false}
            />
          </div>
        </div>
        {allEvents.length === 0 ? (
          <EmptyScreenView subtitle={t('page.event.regular.noEventsFound')} />
        ) : (
          <div className="flex flex-col gap-8">
            {filteredEvents.length === 0 ? (
              <EmptyScreenView
                containerClassName="max-w-96 mx-auto gap-1"
                subtitle={t('page.event.regular.noEventsFound')}
              />
            ) : isMd ? (
              <EventList events={filteredEvents} />
            ) : (
              <EventTable events={eventsPerPage} pagination={pagination} />
            )}
          </div>
        )}
      </div>
    </>
  );
}

/* EventTable (large screens) */

interface EventTableProps {
  events: Event[];
  pagination: Pagination;
}

function EventTable({ events, pagination }: EventTableProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <BaseTable>
      <BaseTableHeader>
        <BaseTableHeaderRow>
          <HeaderCell>{t('page.event.regular.name')}</HeaderCell>
          <HeaderCell>{t('page.event.regular.startDate')}</HeaderCell>
          <HeaderCell>{t('page.event.regular.endDate')}</HeaderCell>
          <HeaderCell>{t('page.event.regular.participation')}</HeaderCell>
        </BaseTableHeaderRow>
      </BaseTableHeader>
      <BaseTableBody>
        <BaseTableSpacerRow />
        {events.map((event) => (
          <BaseTableDataRow
            className="group cursor-pointer transition-all hover:bg-secondary-200"
            key={event.id}
            onClick={() => navigate('/events/details', { state: { eventId: event.id } })}>
            <DataCell>{event.title}</DataCell>
            <DataCell>{formatDateAndTime(event.startTime)}</DataCell>
            <DataCell>{event.endTime ? formatDateAndTime(event.endTime) : '-'}</DataCell>
            <DataCell className="flex items-center justify-between">
              <ParticipationAnswersOverview answers={_.countBy(event.participants, 'answer')} />
              <div className="invisible pr-4 group-hover:visible">
                <FaArrowRight className="h-5 w-5 flex-shrink-0" />
              </div>
            </DataCell>
          </BaseTableDataRow>
        ))}
      </BaseTableBody>
      <BaseTablePagination pagination={pagination} />
    </BaseTable>
  );
}

function HeaderCell({ className, ...props }: PropsOf<'th'>): JSX.Element {
  return <th className={twMerge('py-3 pl-1 text-primary-900', className)} {...props} />;
}

function DataCell({ className, ...props }: PropsOf<'td'>): JSX.Element {
  return (
    <td
      className={twMerge(
        'whitespace-nowrap py-4 pl-2 text-sm font-medium text-primary-900',
        className,
      )}
      {...props}
    />
  );
}

/* EventList (small screens) */

interface EventListProps {
  events: Event[];
}

function EventList({ events }: EventListProps): JSX.Element {
  const navigate = useNavigate();
  const byDates = _.groupBy(events, (event) => formatDate(event.startTime));
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      {_.map(byDates, (eventsByDate, date) => (
        <>
          <div
            key={date}
            className="border-b border-primary-300 py-1 text-sm font-bold text-primary">
            {date}
          </div>
          {eventsByDate.map((event) => (
            <div
              key={event.id}
              className="flex cursor-pointer items-center justify-between rounded-lg bg-secondary-200 p-4 shadow-sm transition-all"
              onClick={() => navigate('/events/details', { state: { eventId: event.id } })}>
              <div>
                <div className="text-sm font-semibold text-primary-900">{event.title}</div>
                <div className="text-xs">
                  <span className="font-medium">{t('page.event.regular.starts')}:</span>{' '}
                  {formatDateAndTime(event.startTime)}
                </div>
                {event.endTime && (
                  <div className="text-xs">
                    <span className="font-medium">{t('page.event.regular.ends')}:</span>{' '}
                    {formatDateAndTime(event.endTime)}
                  </div>
                )}
              </div>
              <div className="flex items-center gap-2">
                <ParticipationAnswersOverview
                  size="sm"
                  answers={_.countBy(event.participants, 'answer')}
                />
                {/*<FaArrowRight className="flex-shrink-0 w-4 h-4" />*/}
              </div>
            </div>
          ))}
        </>
      ))}
    </div>
  );
}

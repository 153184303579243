import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

export default function EmptyScreenView({
  title,
  subtitle,
  containerClassName,
}: {
  title?: string;
  subtitle?: string;
  containerClassName?: string;
}): JSX.Element {
  const { t } = useTranslation('');

  return (
    <section
      className={twMerge(
        'mt-8 flex w-full flex-col items-center justify-center gap-4',
        containerClassName,
      )}>
      <img src="/assets/figures/mess.svg" alt="vera mess" className="w-1/4 max-md:w-1/2" />
      <h2 className="mt-2 text-center font-serif text-2xl">
        {title ?? t('general.emptyScreenTitle')}
      </h2>
      <p className="w-1/2 text-center text-gray-500 max-md:w-[80%]">
        {subtitle ?? t('general.emptyScreenSubtitle')}
      </p>
    </section>
  );
}

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import adminService from '../../services/adminService';
import PanelWithImageLayout from '../components/layouts/PanelWithImageLayout';
import { AdminInviteData } from '../../services/model/adminService.model';
import useAuth from '../../hooks/account/useAuth';
import NameInput from '../components/forms/profile/NameInput';
import QuestionCircle from '../components/misc/QuestionCircle';
import TermsAndConditions from '../components/misc/TermsAndConditions';
import authService from '../../services/authService';
import useDeleteParams from '../../hooks/effects/useDeleteParams';
import Button from '../components/buttons/Button';
// import { setOnboarding } from '../../redux/slices/applicationSlice';
// import useBusinesses from '../../hooks/business/useBusinesses';
// import { OnboardingStep } from '../../types/misc';

export default function AcceptAdminInvitePage() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const token = params.get('token');
  const link = params.get('link');

  useDeleteParams('link');

  if (link) authService.loginMagicLink(link);

  const [invite, setInvite] = useState<AdminInviteData>();
  const auth = useAuth();

  useEffect(() => {
    if (link) return;
    if (!token) {
      toast.error(t('toast.error.invalidLink'));
      navigate('/');
      return;
    }

    adminService
      .getAdminInvite(token)
      .then((res) => {
        if (res) {
          setInvite(res);
        } else {
          toast.error(t('toast.error.invalidLink'));
          navigate('/');
        }
      })
      .catch(() => {
        toast.error(t('toast.error.invalidLink'));
        navigate('/');
      });
  }, [link]);

  const renderView = () => {
    if (!invite) return <></>;
    if (!auth && !invite.accountExists)
      return <NoAuthNoAccountView invite={invite} token={token!} />;
    if (!auth && invite.accountExists) return <NoAuthAccountView invite={invite} token={token!} />;
    if (auth && !invite.accountExists) return <AuthNoAccountView invite={invite} token={token!} />;
    if (auth && invite.email !== auth.email)
      return <AuthWrongAccountView invite={invite} token={token!} />;
    if (auth && invite.email === auth.email)
      return <AuthRightAccountView invite={invite} token={token!} />;
    return <></>;
  };

  const inviterName = invite?.inviterAlias.split('+')[0] || '';
  return (
    <PanelWithImageLayout image={`${process.env.PUBLIC_URL}/assets/misc/connect.svg`} border>
      <PanelWithImageLayout.Header>
        <h1 className="font-serif text-2xl font-medium text-primary-900">
          {t('page.acceptAdminInvite.header.title', {
            inviterName,
            businessName: invite?.businessName || '',
          })}
        </h1>
      </PanelWithImageLayout.Header>

      {renderView()}
    </PanelWithImageLayout>
  );
}

interface ViewProps {
  invite: AdminInviteData | undefined;
  token: string;
}

function NoAuthNoAccountView({ invite, token }: ViewProps): JSX.Element {
  const [alias, setAlias] = useState('');
  const [terms, setTerms] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (!alias || !alias.split('+')[0] || !alias.split('+')[1]) {
      toast.error(t('toast.error.name'));
      return;
    }
    if (!terms) {
      toast.error(t('toast.error.terms'));
      return;
    }

    await adminService.registerAndAccept(token!, invite?.email!, alias);

    // dispatch(setOnboarding(OnboardingStep.INIT));
    toast.success(t('toast.success.acceptedInvite'));
    navigate('/login');
  };

  return (
    <div className="flex h-full w-full flex-1 flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-medium">{t('page.acceptAdminInvite.title')}</h2>
        <p className="text-sm">
          {t('page.acceptAdminInvite.noAuthNoAccount.description', { email: invite?.email })}
        </p>
      </div>

      <NameInput alias={alias} setAlias={setAlias} />

      <div className="flex w-full flex-col">
        <label className="mb-1 pr-2 text-sm">{t('dataType.email')}</label>
        <div className="flex items-center gap-2">
          <p className="flex w-fit flex-shrink-0 flex-grow-0 text-sm">{invite?.email}</p>
          <div className="-ml-1 flex w-1/2 items-center">
            <QuestionCircle className="w-[300px]">
              {t('page.acceptAdminInvite.explanation', {
                inviterName: invite?.inviterAlias.split('+')[0],
              })}
            </QuestionCircle>
          </div>
        </div>
      </div>

      <TermsAndConditions state={terms} setState={() => setTerms(!terms)} />

      <div className="mt-4 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
        <Button variant="primary" onClick={handleSubmit}>
          {t('page.acceptAdminInvite.noAuthNoAccount.button')}
        </Button>
      </div>
    </div>
  );
}

function NoAuthAccountView({ invite, token }: ViewProps): JSX.Element {
  const { t } = useTranslation();

  const handleSubmit = async () => {
    await authService.generateMagicLink(invite?.email!, { adminToken: token });

    toast.success(t('toast.success.sentMagicLink'));
  };

  return (
    <div className="flex h-full w-full flex-1 flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-medium">{t('page.acceptAdminInvite.title')}</h2>
        <p className="text-sm">
          {t('page.acceptAdminInvite.noAuthHasAccount.description', {
            email: invite?.email,
          })}
        </p>
      </div>

      <div className="flex w-full flex-col">
        <label className="mb-1 pr-2 text-sm">{t('dataType.email')}</label>
        <div className="flex items-center gap-2">
          <p className="flex w-fit flex-shrink-0 flex-grow-0 text-sm">{invite?.email}</p>
          <div className="-ml-1 flex w-1/2 items-center">
            <QuestionCircle className="w-[300px]">
              {t('page.acceptAdminInvite.explanation', {
                inviterName: invite?.inviterAlias.split('+')[0],
              })}
            </QuestionCircle>
          </div>
        </div>
      </div>

      <div className="mt-4 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
        <Button variant="primary" onClick={handleSubmit}>
          {t('page.acceptAdminInvite.noAuthHasAccount.button')}
        </Button>
      </div>
    </div>
  );
}

function AuthNoAccountView({ invite, token }: ViewProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    await adminService.requestDifferentEmail(token!);

    toast.success(t('toast.success.requestSent'));
    navigate('/');
  };

  return (
    <div className="flex h-full w-full flex-1 flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-medium">{t('page.acceptAdminInvite.title')}</h2>
        <p className="text-sm">
          {t('page.acceptAdminInvite.authNoAccount.description', {
            email: invite?.email,
            inviterName: invite?.inviterAlias.split('+')[0],
          })}
        </p>
      </div>

      <div className="flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
        <Button variant="primary" onClick={handleSubmit}>
          {t('page.acceptAdminInvite.authNoAccount.button')}
        </Button>
      </div>
    </div>
  );
}

function AuthWrongAccountView({ invite, token }: ViewProps): JSX.Element {
  const { t } = useTranslation();

  const handleSubmit = async () => {
    await authService.generateMagicLink(invite?.email!, { adminToken: token });

    toast.success(t('toast.success.sentMagicLink'));
  };

  return (
    <div className="flex h-full w-full flex-1 flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-medium">{t('page.acceptAdminInvite.title')}</h2>
        <p className="text-sm">
          {t('page.acceptAdminInvite.authWrongAccount.description', { email: invite?.email })}
        </p>
      </div>

      <div className="flex w-full flex-col">
        <label className="mb-1 pr-2 text-sm">{t('dataType.email')}</label>
        <div className="flex items-center gap-2">
          <p className="flex w-fit flex-shrink-0 flex-grow-0 text-sm">{invite?.email}</p>
          <div className="-ml-1 flex w-1/2 items-center">
            <QuestionCircle className="w-[300px]">
              {t('page.acceptAdminInvite.explanation', {
                inviterName: invite?.inviterAlias.split('+')[0],
              })}
            </QuestionCircle>
          </div>
        </div>
      </div>

      <div className="mt-4 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
        <Button variant="primary" onClick={handleSubmit}>
          {t('page.acceptAdminInvite.authWrongAccount.button')}
        </Button>
      </div>
    </div>
  );
}

function AuthRightAccountView({ invite, token }: ViewProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    await adminService.acceptInvite(token!);

    // if (!businesses?.length) dispatch(setOnboarding(OnboardingStep.INIT));

    toast.success(t('toast.success.acceptedInvite'));
    navigate('/');
  };

  return (
    <div className="flex h-full w-full flex-1 flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-medium">{t('page.acceptAdminInvite.title')}</h2>
        <p className="text-sm">
          {t('page.acceptAdminInvite.authHasAccount.description', {
            email: invite?.email,
            businessName: invite?.businessName,
          })}
        </p>
      </div>

      <div className="mt-4 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
        <Button variant="primary" onClick={handleSubmit}>
          {t('page.acceptAdminInvite.authHasAccount.button')}
        </Button>
      </div>
    </div>
  );
}

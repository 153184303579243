import React, { ComponentProps, forwardRef } from 'react';
import {
  MobileDatePicker,
  MobileDateTimePicker,
  renderTimeViewClock,
  StaticDatePicker,
  StaticDateTimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import classNames from 'classnames';

type DatePickerProps = {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  static?: boolean;
  error?: string;
  light?: boolean;
  customFormat?: string;
} & (
  | ({ includeTime: true } & ComponentProps<typeof MobileDateTimePicker>)
  | ({ includeTime?: false } & ComponentProps<typeof MobileDatePicker>)
);

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      date: dateProp,
      setDate,
      includeTime = true,
      customFormat,
      static: staticForm = false,
      slotProps: { textField, ...slotProps } = {},
      light = false,
      error,
      ...props
    },
    ref,
  ) => {
    const baseProps = {
      onChange: (date: dayjs.Dayjs) => setDate(date?.toDate()),
      value: dateProp ? dayjs(dateProp) : undefined,
      slotProps: { textField: { inputRef: ref, ...textField }, ...slotProps },
      ...props,
      className: classNames(props.className || '', {
        '!border-danger !border-2 !border-solid rounded-[15px]': !!error,
        light,
      }),
    };

    const dateTimeProps = {
      ampm: false,
      format: 'DD-MM-YYYY HH:mm',
      viewRenderers: {
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      },
      ...baseProps,
    };

    if (includeTime) {
      if (staticForm) {
        const picker = (
          <StaticDateTimePicker
            {...(dateTimeProps as ComponentProps<typeof StaticDateTimePicker>)}
          />
        );
        return error ? WrapWithError(picker, error) : picker;
      }

      const picker = (
        <MobileDateTimePicker {...(dateTimeProps as ComponentProps<typeof MobileDateTimePicker>)} />
      );
      return error ? WrapWithError(picker, error) : picker;
    }

    if (staticForm) {
      const picker = (
        <StaticDatePicker {...(baseProps as ComponentProps<typeof StaticDatePicker>)} />
      );
      return error ? WrapWithError(picker, error) : picker;
    }

    const picker = (
      <MobileDatePicker
        format={customFormat ?? 'DD-MM-YYYY'}
        {...(baseProps as ComponentProps<typeof MobileDatePicker>)}
      />
    );
    return error ? WrapWithError(picker, error) : picker;
  },
);

function WrapWithError(children: React.ReactNode, error?: string) {
  return (
    <div className="w-full">
      {children}
      {error && <span className="text-sm font-medium text-danger">{error}</span>}
    </div>
  );
}

export default DatePicker;

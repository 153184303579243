import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { BusinessInvite } from '../../../services/model/inviteService.model';
import { createEmptyBusinessInvite } from '../../../utils/formatUtils';
import businessService from '../../../services/businessService';
import inviteService from '../../../services/inviteService';
import { store } from '../../../redux/store';
import { setSelectedBusiness } from '../../../redux/slices/applicationSlice';
import StepFlow, { StepFlowStep } from '../../components/misc/StepFlow';
import InviteForm from '../../components/forms/InviteForm';

export default function ActivateDashboardPage() {
  const { businessId } = useLocation().state ?? {};
  const [invite, setInvite] = useState<BusinessInvite>(createEmptyBusinessInvite());
  const indexState = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!businessId) navigate('/');
  }, []);

  if (!businessId) return <></>;

  const handleStep = (i: 1 | -1) => indexState[1](indexState[0] + i);

  const steps = [
    <InformationStep setStep={handleStep} />,
    <InviteStep invite={invite} setInvite={setInvite} setStep={handleStep} />,
  ];

  return <StepFlow indexState={indexState} steps={steps} />;
}

interface InformationStepProps {
  setStep: (i: 1 | -1) => void;
}

function InformationStep({ setStep }: InformationStepProps) {
  const { t } = useTranslation();
  return (
    <StepFlowStep setStep={setStep} showPrev={false} title={t('page.activateDashboard.info.title')}>
      <p>{t('page.activateDashboard.info.description')}</p>
      <ul className="list-inside list-disc">
        <li>
          <Trans i18nKey="page.activateDashboard.info.bullet1" />
        </li>
        <li>
          <Trans i18nKey="page.activateDashboard.info.bullet2" />
        </li>
        <li>
          <Trans i18nKey="page.activateDashboard.info.bullet3" />
        </li>
      </ul>
    </StepFlowStep>
  );
}

interface InviteFormProps {
  invite: BusinessInvite;
  setInvite: (invite: BusinessInvite) => void;
  setStep: (i: 1 | -1) => void;
}

function InviteStep({ invite, setInvite, setStep }: InviteFormProps) {
  const { t } = useTranslation();
  const { businessId } = useLocation().state ?? {};
  const navigate = useNavigate();
  const handleSubmit = async () => {
    await businessService.activateDashboard(businessId);
    store.dispatch(setSelectedBusiness(businessId));
    await inviteService.updateInvite(invite);
    navigate('/contacts');
  };

  return (
    <StepFlowStep
      setStep={setStep}
      onSubmit={handleSubmit}
      showNext={false}
      title={t('page.activateDashboard.invite.title')}
      subtitle={t('page.inviteLink.invite.subtitle')}>
      <InviteForm invite={invite} setInvite={setInvite} />
    </StepFlowStep>
  );
}

import httpService from './httpService';
import {
  GetAllScoreCardsResponse,
  ScoreCard,
  ScoreCardCreateInfo,
  ScoreCardUpdateInfo,
  ScoreCardWithParticipationData,
} from '../types/lists';
import { store } from '../redux/store';
import { RootState } from '../redux/reducers';

export async function getAllScoreCards(): Promise<GetAllScoreCardsResponse> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .get<GetAllScoreCardsResponse>(`/score-cards/${selectedBusiness}`)
    .then((res) => {
      return {
        membersOfBusiness: res.data.membersOfBusiness.sort((a, b) =>
          a.alias.localeCompare(b.alias),
        ),
        scoreCards: res.data.scoreCards.map(sortNamesInScoreCardWithParticipationData),
      };
    })
    .catch((err) => {
      if (err.response.status === 401) {
        throw new Error('UNAUTHORIZED');
      }
      throw err;
    });
}

export async function createScoreCard(
  data: ScoreCardCreateInfo,
): Promise<ScoreCardWithParticipationData> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .post<ScoreCardWithParticipationData>(`/score-cards/${selectedBusiness}`, data)
    .then((res) => sortNamesInScoreCardWithParticipationData(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        throw new Error('UNAUTHORIZED');
      }
      throw err;
    });
}

export async function deleteScoreCard(scoreCardId: number): Promise<ScoreCard> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .delete<ScoreCard>(`/score-cards/${selectedBusiness}/${scoreCardId}`)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        throw new Error('UNAUTHORIZED');
      }
      throw err;
    });
}

export async function updateScoreCardScore(
  scoreCardId: number,
  connectionId: number,
  newScore: number,
): Promise<ScoreCard> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .put<ScoreCardWithParticipationData>(`/score-cards/${selectedBusiness}/${scoreCardId}/score`, {
      connectionId,
      newScore,
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        throw new Error('UNAUTHORIZED');
      }
      throw err;
    });
}

export async function updateScoreCard(
  scoreCardId: number,
  data: ScoreCardUpdateInfo,
): Promise<ScoreCard> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .put<ScoreCard>(`/score-cards/${selectedBusiness}/${scoreCardId}`, data)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        throw new Error('UNAUTHORIZED');
      }
      throw err;
    });
}

export async function addMemberToScoreCard(
  scoreCardId: number,
  connectionId: number,
): Promise<ScoreCardWithParticipationData> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .put<ScoreCardWithParticipationData>(
      `/score-cards/${selectedBusiness}/${scoreCardId}/connections/`,
      { connectionId },
    )
    .then((res) => sortNamesInScoreCardWithParticipationData(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        throw new Error('UNAUTHORIZED');
      }
      throw err;
    });
}

export async function removeMemberFromScoreCard(
  scoreCardId: number,
  connectionId: number,
): Promise<ScoreCardWithParticipationData> {
  const { selectedBusiness } = (store.getState() as RootState).application;

  return httpService
    .delete<ScoreCardWithParticipationData>(
      `/score-cards/${selectedBusiness}/${scoreCardId}/connections/${connectionId}`,
    )
    .then((res) => sortNamesInScoreCardWithParticipationData(res.data));
}

function sortNamesInScoreCardWithParticipationData(
  scoreCard: ScoreCardWithParticipationData,
): ScoreCardWithParticipationData {
  return {
    ...scoreCard,
    scoreCardOnB2CConnection: scoreCard.scoreCardOnB2CConnection.sort((a, b) => {
      return `${a.b2cConnection.user.firstName} ${a.b2cConnection.user.lastName}`.localeCompare(
        `${b.b2cConnection.user.firstName} ${b.b2cConnection.user.lastName}`,
      );
    }),
  };
}

import React, { useEffect, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { FaUserSecret, FaCircleInfo } from 'react-icons/fa6';
import { HiUserGroup } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import ApplicationLayout from '../../components/layouts/ApplicationLayout/ApplicationLayout';
import Button from '../../components/buttons/Button';
import { isCommunity, isMoments } from '../../../constants';
import { BusinessType, CommunityType, MomentType } from '../../../types/business';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import { CreateBusinessForm, CreateUserForm } from '../../../types/forms';
import ImageInput from '../../components/forms/ImageInput';
import NameInput from '../../components/forms/profile/NameInput';
import TermsAndConditions from '../../components/misc/TermsAndConditions';
import authService from '../../../services/authService';
import useAuth from '../../../hooks/account/useAuth';
import { businessTypeIcons } from '../../../utils/iconUtils';
import IconBadge from '../../components/icons/IconBadge';
import businessService from '../../../services/businessService';
import { wipeSelectedBusiness } from '../../../redux/slices/applicationSlice';
import { store } from '../../../redux/store';
import StepFlow, { StepFlowStep } from '../../components/misc/StepFlow';

export default function OnboardingPage(): JSX.Element {
  const [step, setStep] = useState<number>(0);
  const [business, setBusiness] = useState<CreateBusinessForm>({ businessDescription: '' });

  const auth = useAuth();
  const location = useLocation();
  const [params] = useSearchParams();
  const type = params.get('type') as BusinessType;

  const handleStep = (diff: 1 | -1) => setStep(step + diff);

  useLayoutEffect((): void => {
    if (location.state) {
      const data: CreateBusinessForm = location.state;

      setBusiness({ ...data, businessDescription: data.businessDescription || '' });
      setStep(isCommunity ? 3 : 2);
    } else if (type) {
      setBusiness((prevData: any) => ({ ...prevData, businessType: type }));
      setStep(2);
    }
  }, []);

  useEffect(() => {
    store.dispatch(wipeSelectedBusiness());
  }, []);

  const steps = [
    <IntroductionView setStep={handleStep} />,
    <BusinessTypeView
      selected={business.businessType}
      setSelected={(selected) => setBusiness({ ...business, businessType: selected })}
      setStep={handleStep}
    />,
    isCommunity && (
      <ConnectedMembersView business={business} setBusiness={setBusiness} setStep={handleStep} />
    ),
    <BusinessInfoStep business={business} setBusiness={setBusiness} setStep={handleStep} />,
    !auth && <LoginRegisterView business={business} setStep={handleStep} />,
  ].filter(Boolean) as JSX.Element[];

  return (
    <ApplicationLayout collapsed>
      <StepFlow steps={steps} indexState={[step, setStep]} />
    </ApplicationLayout>
  );
}

interface ViewProps {
  setStep: (step: 1 | -1) => void;
}

function IntroductionView({ setStep }: ViewProps) {
  const { t } = useBusinessTranslation();
  return (
    <StepFlowStep setStep={setStep} showPrev={false} className="max-w-full">
      <div className="mx-auto my-auto flex h-full w-full flex-col items-center justify-center gap-2 md:flex-row lg:w-[70%]">
        <img
          alt="Vera Phone"
          src={`${process.env.PUBLIC_URL}/assets/figures/phone_hi.svg`}
          className="w-[50%]"
        />
        <div className="flex w-full flex-col gap-1">
          <h1 className="font-serif text-3xl font-semibold">
            {t('page.onboarding.introduction.title')}
          </h1>
          <p> {t('page.onboarding.introduction.subtitle')}</p>
        </div>
      </div>
    </StepFlowStep>
  );
}

type BusinessTypeViewProps = {
  selected: BusinessType | undefined;
  setSelected: (type: BusinessType | undefined) => void;
} & ViewProps;

function BusinessTypeView({ selected, setSelected, setStep }: BusinessTypeViewProps) {
  const { t } = useBusinessTranslation();
  const typeOptions = (
    isMoments
      ? Object.keys(MomentType)
      : Object.keys(
          _.omit(CommunityType, [CommunityType.FAMILY, CommunityType.FRIENDS, CommunityType.WORK]),
        )
  ) as BusinessType[];

  return (
    <StepFlowStep
      setStep={setStep}
      disabled={!selected}
      title={t('page.onboarding.type.title')}
      subtitle={t('page.onboarding.type.subtitle')}>
      {typeOptions.map((type) => (
        <div
          key={type}
          onClick={() => setSelected(type === selected ? undefined : type)}
          className={classNames(
            'flex w-full cursor-pointer justify-between rounded-[8px] border border-secondary-200 px-4 py-2 transition-all',
            { 'bg-secondary-200': selected === type },
          )}>
          <div className="flex gap-2">
            <IconBadge icon={businessTypeIcons[type]} className="rounded-[6px] p-1" />
            <div>
              <h3 className="font-serif text-lg">
                {t(`page.onboarding.type.${type.toLowerCase().split('_')[0]}.name`)}
              </h3>
              <p> {t(`page.onboarding.type.${type.toLowerCase().split('_')[0]}.description`)}</p>
            </div>
          </div>
          <input type="checkbox" checked={selected === type} />
        </div>
      ))}
    </StepFlowStep>
  );
}

type ConnectedMembersViewProps = {
  business: CreateBusinessForm;
  setBusiness: (business: CreateBusinessForm) => void;
} & ViewProps;

function ConnectedMembersView({ business, setBusiness, setStep }: ConnectedMembersViewProps) {
  const { t } = useBusinessTranslation();

  useLayoutEffect(() => setBusiness({ ...business, connectedMembers: true }), []);

  return (
    <StepFlowStep
      disabled={business.connectedMembers === undefined}
      setStep={setStep}
      title={t('page.onboarding.connectedMembers.title')}
      subtitle={t('page.onboarding.connectedMembers.subtitle')}
      className="gap-4">
      <div className="flex flex-1 flex-row gap-4">
        <div
          onClick={() => setBusiness({ ...business, connectedMembers: true })}
          className={classNames(
            'flex w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-[20px] border-2 p-4',
            {
              'border-primary-300 bg-secondary-200 text-secondary-50': business.connectedMembers,
              'border-secondary-200 text-secondary-200': !business.connectedMembers,
            },
          )}>
          <HiUserGroup className="h-12 w-12" />
          <p className="font-semibold">{t('page.onboarding.connectedMembers.yes')}</p>
        </div>
        <div
          onClick={() => setBusiness({ ...business, connectedMembers: false })}
          className={classNames(
            'flex w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-[20px] border-2 p-4',
            {
              'border-primary-300 bg-secondary-200 text-secondary-50': !business.connectedMembers,
              'border-secondary-200 text-secondary-200': business.connectedMembers,
            },
          )}>
          <FaUserSecret className="h-12 w-12" />
          <p className="font-semibold">{t('page.onboarding.connectedMembers.no')}</p>
        </div>
      </div>
      <div className="mx-auto flex w-full max-w-lg gap-2 rounded-[20px] bg-primary-300 p-2">
        <FaCircleInfo className="mt-1 h-4 w-4 flex-shrink-0 items-start justify-start text-secondary-50" />
        <p className="text-sm">
          {t(
            `page.onboarding.connectedMembers.${
              business.connectedMembers ? 'connected' : 'notConnected'
            }`,
          )}
        </p>
      </div>
    </StepFlowStep>
  );
}

type BusinessInfoStepProps = {
  business: CreateBusinessForm;
  setBusiness: (business: CreateBusinessForm) => void;
} & ViewProps;

function BusinessInfoStep({ business, setBusiness, setStep }: BusinessInfoStepProps) {
  const { t } = useBusinessTranslation();
  const auth = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (business.communityLink && !business.communityLink.toLowerCase().includes('whatsapp')) {
      toast.error(t('toast.error.invalidWhatsapp'));
      return;
    }
    await businessService.createBusiness(business);
    navigate('/overview');
    // if (!businesses?.length) dispatch(setOnboarding(OnboardingStep.INIT));
  };

  const handleNext = (i: 1 | -1) => {
    if (i < 0) {
      setStep(i);
      return;
    }
    if (business.communityLink && !business.communityLink.toLowerCase().includes('whatsapp')) {
      toast.error(t('toast.error.invalidWhatsapp'));
      return;
    }
    setStep(1);
  };

  return (
    <StepFlowStep
      showNext={!auth}
      disabled={!business.businessType}
      onSubmit={auth ? handleSubmit : undefined}
      setStep={handleNext}
      title={t(`page.onboarding.info.${business?.businessType?.toLowerCase().split('_')[0]}.title`)}
      subtitle={t(
        `page.onboarding.info.${business?.businessType?.toLowerCase().split('_')[0]}.subtitle`,
      )}>
      <div className="relative flex w-full flex-col gap-1">
        <p className="ml-4">{t(`page.onboarding.info.name`)}</p>
        <input
          type="text"
          className="w-full"
          value={business.businessName}
          onChange={(e) => setBusiness({ ...business, businessName: e.target.value })}
        />
        <p className="absolute right-4 top-0">{t('general.mandatory')}</p>
      </div>
      {isCommunity && (
        <>
          <div className="flex w-full flex-col gap-1">
            <p className="ml-4">{t(`page.onboarding.info.description`)}</p>
            <textarea
              className="w-full resize-none"
              value={business.businessDescription}
              onChange={(e) => setBusiness({ ...business, businessDescription: e.target.value })}
            />
          </div>
          <div className="flex w-full flex-col gap-1">
            <p className="ml-4">{t(`general.whatsappLink`)}</p>
            <input
              type="text"
              className="w-full"
              value={business.communityLink ?? ''}
              onChange={(e) => setBusiness({ ...business, communityLink: e.target.value })}
            />
          </div>
        </>
      )}
    </StepFlowStep>
  );
}

type LoginRegisterViewProps = {
  business: CreateBusinessForm;
} & ViewProps;

function LoginRegisterView({ business, setStep }: LoginRegisterViewProps) {
  const { t } = useBusinessTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [view, setView] = useState<'LOGIN' | 'REGISTER'>('REGISTER');
  const [formData, setFormData] = useState<CreateUserForm>({});
  const [terms, setTerms] = useState<boolean>(false);

  const handleRegister = async (): Promise<void> => {
    if (!formData.firstName || !formData.lastName || !formData.email) {
      toast.error(t('toast.error.allFields'));
      return;
    }
    if (!terms) {
      toast.error(t('toast.error.terms'));
      return;
    }
    await authService.instantRegister(formData, business);
    navigate('/overview');
    // dispatch(setOnboarding(OnboardingStep.INIT));
  };

  const handleLogin = async (): Promise<void> => {
    if (!formData.email) {
      toast.error(t('toast.error.allFields'));
      return;
    }
    await authService.generateMagicLink(formData.email, { business });
    toast.success(t('toast.success.sentMagicLink'));
  };

  return (
    <StepFlowStep
      showNext={false}
      setStep={setStep}
      onSubmit={handleRegister}
      disabled={view === 'LOGIN'}
      submitText={t('page.onboarding.confirmation.submit')}
      title={t(`page.onboarding.confirmation.${view.toLowerCase()}.title`)}
      subtitle={t(`page.onboarding.confirmation.${view.toLowerCase()}.subtitle`)}>
      <div className="ml-4 flex justify-end gap-1 text-sm">
        {view === 'REGISTER' ? (
          <>
            {t('auth.alreadyHaveAccount')}
            <Button className="px-0 py-0 underline" onClick={(): void => setView('LOGIN')}>
              {t('auth.login')}
            </Button>
          </>
        ) : (
          <>
            {t('auth.noAccountYet')}
            <Button className="px-0 py-0 underline" onClick={(): void => setView('REGISTER')}>
              {t('auth.register')}
            </Button>
          </>
        )}
      </div>
      <div className="flex w-full flex-col gap-4">
        {view === 'REGISTER' && (
          <NameInput
            alias={`${formData.firstName || ''}+${formData.lastName || ''}`}
            setAlias={(alias: string) => {
              const [firstName, lastName] = alias.split('+');
              setFormData({ ...formData, firstName, lastName });
            }}
          />
        )}
        <div className="w-full">
          <p className="mb-1 pr-2 font-medium">Email</p>
          <input
            type="text"
            placeholder="Email"
            className="w-full"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
        </div>
        {view === 'REGISTER' && <TermsAndConditions state={terms} setState={setTerms} />}
        {view === 'LOGIN' && (
          <div className="flex justify-end">
            <Button variant="primary" onClick={handleLogin}>
              {t('auth.sendLink')}
            </Button>
          </div>
        )}
      </div>
    </StepFlowStep>
  );
}

interface ChoosePictureViewProps {
  picture: string | undefined;
  setPicture: (picture: string | undefined) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ChoosePictureView({ picture, setPicture }: ChoosePictureViewProps) {
  return (
    <div className="flex h-full w-full flex-1 flex-col gap-4">
      <div className="flex w-full flex-col items-center justify-center gap-2 md:flex-row lg:w-[50%]">
        <img
          alt="Vera Phone"
          src={`${process.env.PUBLIC_URL}/assets/figures/phone_hi.svg`}
          className="w-[50%]"
        />
        <div className="flex w-full flex-col gap-1">
          <h1 className="font-serif text-3xl font-semibold">Kies jouw type community</h1>
          <p>
            Bereid je voor op een geweldig moment! Wat wil je organiseren? Kies uit de opties
            hieronder en start je planning.
          </p>
        </div>
      </div>
      <div className="mx-auto flex w-full flex-col items-center gap-4 md:w-[60%]">
        <div className={`${picture ? 'w-fit' : 'w-full'} flex flex-col gap-1`}>
          <p className="">Upload a picture</p>
          <ImageInput
            image={picture}
            setImage={setPicture}
            className={`h-64 lg:h-72 ${picture ? 'w-fit' : 'w-full'}`}
            iconClassName="w-14 h-14"
          />
        </div>
      </div>
    </div>
  );
}

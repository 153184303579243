import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FaPlus } from 'react-icons/fa6';
import { Address, ProfileDataType } from '../../../types/Profile';
import userService from '../../../services/userService';
import useProfileFields from '../../../hooks/account/useProfileFields';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import ProfileModal from '../modals/ProfileModal';

interface AddressPickerProps {
  selectedAddress: Address | null;
  setSelectedAddress: (address: Address | null) => void;
  addButton?: boolean;
}
export default function AddressPicker({
  selectedAddress,
  setSelectedAddress,
  addButton = false,
}: AddressPickerProps): JSX.Element {
  const { t } = useBusinessTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const addresses = useProfileFields()
    .filter((field) => field.dataType === ProfileDataType.ADDRESS)
    .map((f) => f.address) as Address[];

  useEffect(() => {
    userService.getUserData();
  }, []);

  const handleAddressChange = (address: Address): void => {
    if (_.isEqual(address, selectedAddress)) setSelectedAddress(null);
    else setSelectedAddress(address);
  };

  const getAddressColor = (address: Address): string => {
    if (_.isEqual(address, selectedAddress)) return 'bg-primary-300 border-primary-300';
    return '!bg-secondary-50 border border-secondary-200';
  };

  return (
    <>
      <div className="grid w-full grid-cols-2 gap-x-4 gap-y-4">
        {addresses.map(
          (address: Address, index: number): JSX.Element => (
            <div
              key={index}
              data-testid={`address-${index}`}
              className={`relative flex w-full min-w-[220px] cursor-pointer justify-between rounded-[10px] border-2 bg-secondary-200 bg-opacity-50 p-4 transition-all ${_.isEqual(address, selectedAddress) ? 'border-primary-300' : 'border-secondary-200'}`}
              onClick={(): void => handleAddressChange(address)}>
              <div className="px-2">
                <p>{`${address.street} ${address.houseNumber}${
                  address.houseNumberAddition || ''
                }`}</p>
                <p>{`${address.postCode} ${address.city}`}</p>
                <p>{address.country}</p>
              </div>
              <input type="checkbox" checked={_.isEqual(address, selectedAddress)} />
            </div>
          ),
        )}
        {addButton && (
          <div
            className="group relative flex w-full min-w-[220px] cursor-pointer items-center justify-center rounded-[10px] border-2 border-dashed border-primary-300 p-4 transition-all hover:border-opacity-80 hover:bg-primary-300 hover:bg-opacity-10"
            onClick={(): void => setModalOpen(true)}>
            <div className="flex flex-col items-center justify-center text-primary">
              <FaPlus className="h-5 w-5" />
              {t('page.createCommunication.details.add')}
            </div>
          </div>
        )}
      </div>
      <ProfileModal
        open={modalOpen}
        setOpen={setModalOpen}
        type={ProfileDataType.ADDRESS}
        modalType="CREATE"
      />
    </>
  );
}

import React, { HTMLAttributes } from 'react';
import { COLOR_SECONDARY_50 } from '../../../constants';

type VeraAgendaProps = HTMLAttributes<SVGElement> & {
  color?: string;
};

export default function VeraAgenda({ color = COLOR_SECONDARY_50, ...props }: VeraAgendaProps) {
  return (
    <svg
      width="146"
      height="136"
      viewBox="0 0 146 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.3373 130.644C14.8996 133.915 18.1879 136 21.809 136H129.468C135.161 136 138.747 129.918 136.059 124.893C135.391 123.649 134.755 122.372 134.148 121.071C133.83 120.387 133.145 119.972 132.393 119.973C132.389 119.973 132.385 119.973 132.38 119.973H23.5469C20.704 119.973 18.0977 118.368 16.8317 115.821C11.5317 105.138 -0.944824 74.2259 4.07462 27.4541C3.53882 28.2844 3.16394 29.2406 2.99025 30.2645C-5.90245 82.6778 7.22957 117.859 13.3373 130.644Z"
        fill={color}
      />
      <path
        d="M131.303 7.96549C130.207 26.1732 129.364 71.5211 145.081 100.898C147.771 105.923 144.188 112.004 138.493 112.004H29.6593C26.8164 112.004 24.2139 110.402 22.948 107.854C17.1563 96.1968 2.82611 60.4338 12.0132 6.26646C12.6265 2.65026 15.754 -0.0019455 19.4173 -0.0019455H123.813C128.137 -0.0019455 131.563 3.64275 131.303 7.96549Z"
        fill={color}
      />
      <path
        d="M104.647 60.1711L53.4176 44.6185C50.7318 43.8039 47.9379 45.4843 47.3815 48.2397C45.5759 57.1858 45.1844 75.6353 69.7399 83.0898C94.2964 90.5443 104.201 74.9828 107.66 66.5388C108.726 63.9377 107.334 60.9867 104.647 60.1711Z"
        fill="#19223F"
      />
      <path
        d="M94.6491 39.6557C93.8846 42.1812 95.3095 44.8481 97.8305 45.6136C100.352 46.3791 103.016 44.9523 103.78 42.4278C104.544 39.9034 103.12 37.2354 100.599 36.4699C98.0768 35.7044 95.4135 37.1313 94.6491 39.6557Z"
        fill="#19223F"
      />
      <path
        d="M63.9808 30.3432C63.2163 32.8687 64.6412 35.5356 67.1622 36.3011C69.6842 37.0666 72.3475 35.6398 73.1119 33.1153C73.8764 30.5909 72.4515 27.9229 69.9305 27.1574C67.4095 26.3919 64.7452 27.8188 63.9808 30.3432Z"
        fill="#19223F"
      />
    </svg>
  );
}

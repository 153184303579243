import { Frequency } from './event';
import { Address } from './Profile';

export enum EmailType {
  BASIC = 'BASIC',
  AGENDA = 'AGENDA',
}

export enum AgendaPeriod {
  WEEK = 'WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  MONTH = 'MONTH',
}

export enum EmailTemplate {
  BLANK = 'BLANK',
  VERA = 'VERA',
}

export interface ScheduledEmail {
  emailId: number;
  title: string;
  body: string;
  sendDate: Date;
  isSent: boolean;
  businessId: number;
  localContactIds: number[];
  connectionIds: number[];
  template: EmailTemplate;
  type: EmailType;

  // AGENDA
  footer?: string;
  endDate?: Date;
  period?: AgendaPeriod;
  recurrenceId?: number;
  frequency: Frequency;
}

export interface ScheduledNotification {
  notificationId: number;
  message: string;
  sendDate: Date;
  isSent: boolean;
  businessId: number;
  connectionIds: number[];
}

export interface Communications {
  emails: ScheduledEmail[];
  notifications: ScheduledNotification[];
  postcards: PostcardOrder[];
}

export enum CommunicationType {
  EMAIL = 'EMAIL',
  NOTIFICATION = 'NOTIFICATION',
  POSTCARD = 'POSTCARD',
}

export interface PostcardOrder {
  orderId: number;
  message: string;
  connectionIds: number[];
  localContactIds: number[];
  momentConnectionIds: number[];
  senderAddress: Address;
  template: string;
  sendDate: Date;
  clientSecret: string;
  code: string;
  fileName?: string;
  finish: PostcardFinish;
}

export enum PostcardFinish {
  GLOSSY = 'GLOSSY',
  MATTE = 'MATTE',
}

import React from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { PropsOf } from '@headlessui/react/dist/types';

export default function UnderlinedLink({
  className,
  ...props
}: {
  className?: string;
  children: React.ReactNode;
} & Omit<PropsOf<typeof Link>, 'children'>): JSX.Element {
  return <Link className={twMerge('underline hover:opacity-80', className)} {...props} />;
}

import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { Task } from '../../services/model/taskService.model';

export default function useTasks(): Task[] {
  const {
    application: { selectedBusiness },
    tasks: { taskMap },
  } = useSelector((state: RootState) => state);

  return taskMap[selectedBusiness!] || [];
}

import React from 'react';
import { IoWarning } from 'react-icons/io5';
import { Admin } from '../../../types/business';
import { adminRoleToString, capitalizeFirstLetter } from '../../../utils/stringUtils';
import AdminActions from '../dropdown/AdminActions';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import ProfilePicture from '../misc/ProfilePicture';
import IconBadge from '../icons/IconBadge';
import {
  BaseTable,
  BaseTableBody,
  BaseTableDataRow,
  BaseTableHeader,
  BaseTableHeaderRow,
} from './BaseTable';

interface AdminsTableProps {
  data: Admin[];
}

export default function AdminsTable({ data }: AdminsTableProps): JSX.Element {
  const { t } = useBusinessTranslation();

  return (
    <BaseTable className="z-0 min-w-full overflow-x-scroll">
      <BaseTableHeader className="z-0">
        <BaseTableHeaderRow>
          <th>{t('general.name')}</th>
          <th>{t('general.role')}</th>
          <th className="text-center">{t('general.actions')}</th>
        </BaseTableHeaderRow>
      </BaseTableHeader>
      <BaseTableBody className="z-0">
        {data.map((row) => (
          <BaseTableDataRow key={row.adminId} className="group">
            <td className="!py-4">
              <div className="flex flex-shrink-0 items-center">
                <ProfilePicture
                  picture={row.picture}
                  alias={row.display.replace(' ', '+')}
                  className="mr-2 h-10 w-10 text-lg"
                />
                <p className="block">{row.display.split('+').join(' ') || '-'}</p>
                {row.pending && (
                  <div className="relative h-fit w-fit">
                    <IconBadge icon={IoWarning} className="peer ml-1 h-7 w-7" />
                    <div className="absolute -bottom-7 -right-20 rounded-lg bg-primary-900 p-1 px-2 text-xs text-secondary-50 opacity-0 transition-all duration-200 peer-hover:opacity-100">
                      {t('general.pending')}
                    </div>
                  </div>
                )}
              </div>
            </td>

            <td>{capitalizeFirstLetter(adminRoleToString(row.role))}</td>

            <td>
              <div className="flex h-full flex-1 items-center justify-center">
                <AdminActions admin={row} />
              </div>
            </td>
          </BaseTableDataRow>
        ))}
      </BaseTableBody>
    </BaseTable>
  );
}

import React from 'react';
import { Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

type WithTooltipProps = {
  children: JSX.Element;
  tooltip: string;
  className?: string;
};

export default function WithTooltip({
  children,
  tooltip,
  className,
}: WithTooltipProps): JSX.Element {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}>
      {children}
      <Transition
        as={React.Fragment}
        show={showTooltip}
        enter="transition-all duration-200"
        enterFrom="opacity-0 transform scale-95 -translate-y-1"
        enterTo="opacity-100 transform scale-100 -translate-y-0"
        leave="transition-all duration-200"
        leaveFrom="opacity-100 transform scale-100 -translate-y-0"
        leaveTo="opacity-0 transform scale-95 -translate-y-1">
        <div
          className={twMerge(
            'absolute left-1/2 top-[calc(100%+0.25rem)] max-w-72 -translate-x-1/2 overflow-x-visible whitespace-nowrap rounded-md bg-gray-800 px-2 py-1 text-xs text-white',
            className,
          )}>
          {tooltip}
        </div>
      </Transition>
    </div>
  );
}

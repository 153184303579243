import { useTranslation } from 'react-i18next';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import ContactCard from './ContactCard';

export default function FeedbackCards({ className }: { className?: string }): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.manageSubscription' });

  return (
    <div className={twMerge('flex items-center gap-4 max-md:flex-col', className)}>
      <ContactCard
        containerClassName="flex-1"
        veraType="vera_smile"
        title={t('feedback.title')}
        subtitle={t('feedback.subtitle')}
        ctaText={t('feedback.ctaText')}
        href="/feedback"
      />
      <ContactCard
        containerClassName="flex-1"
        veraType="vera_sad"
        title={t('contact.title')}
        subtitle={t('contact.subtitle')}
        ctaText={t('contact.ctaText')}
        href="/feedback"
      />
    </div>
  );
}

/**
 * dropdown that shows the actions that can be performed on a connection
 * possible actions: assign tag, break the connection, email, ask details
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TbEdit, TbHeartBroken, TbMail, TbShare, TbTrash, TbUserUp } from 'react-icons/tb';
import { LiaHandshake } from 'react-icons/lia';
import WarningModal from '../modals/WarningModal';
import { ConnectionType } from '../../../redux/slices/connectionsSlice';
import LocalContactModal from '../modals/LocalContactModal';
import localContactService from '../../../services/localContactService';
import useConnection from '../../../hooks/business/useConnection';
import useAdmins from '../../../hooks/business/useAdmins';
import adminService from '../../../services/adminService';
import { AdminRole } from '../../../types/business';
import IntroductionModal from '../modals/IntroductionModal';
import { getUserId } from '../../../services/httpService';
import PersonalMomentConnectionModal from '../modals/PersonalMomentConnectionModal';
import InvitationLinkModal from '../modals/InvitationLinkModal';
import { isCommunity } from '../../../constants';
import SharedWithCommunityModal from '../modals/SharedWithCommunityModal';
import { ActionMenu, ActionMenuButton } from '../menus/ActionMenu';

interface ConnectionActionsProps {
  connectionId: string;
  connectionType: ConnectionType;
  handleDelete: (id: string, connectionType: ConnectionType) => Promise<void>;
}

export default function ConnectionActions({
  connectionId,
  connectionType,
  handleDelete,
}: ConnectionActionsProps): JSX.Element {
  const { t } = useTranslation();
  const connection = useConnection(connectionId, connectionType);
  const admins = useAdmins();

  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [localContactDialog, setLocalContactDialog] = React.useState(false);
  const [introductionDialog, setIntroductionDialog] = React.useState(false);
  const [personalMomentConnectionDialog, setPersonalMomentConnectionDialog] = React.useState(false);
  const [localContactInvitationLinkDialog, setLocalContactInvitationLinkDialog] =
    React.useState(false);
  const [sharedWithCommunityDialog, setSharedWithCommunityDialog] = React.useState(false);

  const inviteContact = async (): Promise<void> => {
    await localContactService.inviteContacts([connectionId]);
    toast.success('Invitation sent!');
  };

  const inviteAdmin = async (role: AdminRole): Promise<void> => {
    if (!connection?.userId) return;
    await adminService.inviteByUserIds([connection?.userId], role);
    toast.success(t('toast.success.inviteSent_one'));
  };
  return (
    <>
      <ActionMenu>
        {connectionType === ConnectionType.B2C_CONNECTION &&
          !admins
            .map((a) => a.userId)
            .filter((x) => x)
            .includes(connection?.userId) && (
            <ActionMenuButton
              icon={TbUserUp}
              text={t('component.actions.connection.admin')}
              onClick={() => inviteAdmin(AdminRole.OWNER)}
            />
          )}
        {connectionType === ConnectionType.B2C_CONNECTION &&
          connection?.userId !== +getUserId() && (
            <ActionMenuButton
              icon={LiaHandshake}
              text={t('component.actions.connection.introduction')}
              onClick={(): void => setIntroductionDialog(true)}
            />
          )}
        {connectionType === ConnectionType.PERSONAL_MOMENT_CONNECTION && (
          <ActionMenuButton
            icon={TbEdit}
            text={t('component.actions.connection.edit')}
            onClick={(): void => setPersonalMomentConnectionDialog(true)}
          />
        )}
        {isCommunity && connection?.userId === getUserId() && (
          <ActionMenuButton
            icon={TbEdit}
            text={t('component.actions.connection.edit')}
            onClick={(): void => setSharedWithCommunityDialog(true)}
          />
        )}
        {connectionType === ConnectionType.LOCAL_CONTACT && (
          <>
            <ActionMenuButton
              icon={TbEdit}
              text={t('component.actions.connection.edit')}
              onClick={(): void => setLocalContactDialog(true)}
            />
            {connection?.fields.EMAIL && (
              <ActionMenuButton
                icon={TbMail}
                text={t('component.actions.connection.sendInvite')}
                onClick={inviteContact}
              />
            )}
            <ActionMenuButton
              icon={TbShare}
              text={t('component.actions.connection.getInvite')}
              onClick={(): void => setLocalContactInvitationLinkDialog(true)}
            />
          </>
        )}
        <ActionMenuButton
          className="text-danger"
          icon={connectionType === ConnectionType.LOCAL_CONTACT ? TbTrash : TbHeartBroken}
          text={
            connectionType === ConnectionType.LOCAL_CONTACT
              ? t('component.actions.connection.deleteContact')
              : t('component.actions.connection.deleteConnection')
          }
          onClick={(): void => setDeleteDialog(true)}
        />
      </ActionMenu>
      <WarningModal
        open={deleteDialog}
        setOpen={setDeleteDialog}
        onClose={(): Promise<void> => handleDelete(connectionId, connectionType)}
        title={
          connectionType === ConnectionType.LOCAL_CONTACT ||
          connectionType === ConnectionType.PERSONAL_MOMENT_CONNECTION
            ? t('warning.deleteLocalContact.title')
            : t('warning.deleteConnection.title')
        }
        saveClassName="bg-danger text-secondary-50"
        description={
          connectionType === ConnectionType.LOCAL_CONTACT ||
          connectionType === ConnectionType.PERSONAL_MOMENT_CONNECTION
            ? t('warning.deleteLocalContact.description')
            : t('warning.deleteConnection.description')
        }
        button={
          connectionType === ConnectionType.LOCAL_CONTACT ||
          connectionType === ConnectionType.PERSONAL_MOMENT_CONNECTION
            ? t('warning.deleteLocalContact.title')
            : t('warning.deleteConnection.title')
        }
      />
      {connectionType === ConnectionType.LOCAL_CONTACT && (
        <>
          <LocalContactModal
            open={localContactDialog}
            setOpen={setLocalContactDialog}
            connectionId={connectionId}
          />
          <InvitationLinkModal
            open={localContactInvitationLinkDialog}
            setOpen={setLocalContactInvitationLinkDialog}
            connectionId={connectionId}
          />
        </>
      )}
      {connectionType === ConnectionType.B2C_CONNECTION && (
        <IntroductionModal
          open={introductionDialog}
          setOpen={setIntroductionDialog}
          alias={`${connection?.fields.FIRST_NAME}+${connection?.fields.LAST_NAME}`}
          userId={connection?.userId!}
        />
      )}
      {connectionType === ConnectionType.PERSONAL_MOMENT_CONNECTION && (
        <PersonalMomentConnectionModal
          connection={connection}
          open={personalMomentConnectionDialog}
          setOpen={setPersonalMomentConnectionDialog}
        />
      )}
      {isCommunity && connection?.userId === getUserId() && (
        <SharedWithCommunityModal
          open={sharedWithCommunityDialog}
          setOpen={setSharedWithCommunityDialog}
        />
      )}
    </>
  );
}

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { FaArrowLeft, FaArrowTrendUp, FaAt, FaLocationArrow } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useSelector } from 'react-redux';
import ApplicationLayout from '../../components/layouts/ApplicationLayout/ApplicationLayout';
import Button from '../../components/buttons/Button';
import { associationsRaceService } from '../../../services/associationsRaceService';
import ApiImage from '../../components/misc/ApiImage';
import IconBadge from '../../components/icons/IconBadge';
import { Association } from '../../../types/race';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import BusinessCard from '../../components/cards/BusinessCard';
import {
  COLOR_PRIMARY_300,
  COLOR_PRIMARY_900,
  COLOR_SECONDARY_50,
  MAXIMUM_COMMUNITIES_PER_ASSOCIATION,
} from '../../../constants';
import { RootState } from '../../../redux/reducers';
import { ProfileDataType, ProfileField } from '../../../types/Profile';
import { createEmptyField } from '../../../utils/formatUtils';
import { AssociationModal } from './RaceOverviewPage';
import { isEmptyAddress } from '../../../utils/validationUtils';

export default function AssociationPage(): JSX.Element | null {
  const { associationId } = useParams();
  const role = useSelector((state: RootState) => state.application.role);
  const [addAssociationModalOpen, setAddAssociationModalOpen] = React.useState(false);
  const [image, setImage] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  const [website, setWebsite] = useState<string | undefined>();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!associationId) {
      console.error('Association ID is required');
      return;
    }
    associationsRaceService
      .updateAssociation(
        +associationId,
        name,
        description,
        website,
        image,
        !modalField || isEmptyAddress(modalField) ? undefined : modalField.address,
      )
      .then(() => {
        setAddAssociationModalOpen(false);
      });
  };

  const [modalField, setModalField] = useState<ProfileField | undefined>();
  useLayoutEffect(() => {
    if (modalField) setModalField(modalField);
    else setModalField(createEmptyField(ProfileDataType.ADDRESS));
  }, []);
  const navigate = useNavigate();
  const [association, setAssociation] = React.useState<Association>();
  const [currentTrialMonths, setCurrentTrialMonths] = React.useState(0);
  const { t } = useTranslation('', { keyPrefix: 'page.race.association' });
  const selectedBusiness = useSelectedBusiness();

  if (!associationId) {
    navigate('/race');
    return null;
  }

  const fetchAssociation = () => {
    associationsRaceService.retrieveAssociation(+associationId).then(setAssociation);
  };

  useEffect(() => {
    associationsRaceService.retrieveAssociation(+associationId).then(setAssociation);

    associationsRaceService.getCurrentTrialPeriod(+associationId).then(setCurrentTrialMonths);
  }, []);

  if (!association) return null;

  return (
    <ApplicationLayout collapsed>
      <main className="flex flex-col gap-8">
        <Button onClick={() => navigate('/race')}>
          <FaArrowLeft /> {t('back')}
        </Button>
        <div className="flex w-full justify-between gap-2 rounded-lg border-2 border-secondary-200 bg-secondary-200 bg-opacity-50 p-4 max-md:flex-col max-md:items-center">
          <div className="flex flex-1 flex-col max-md:items-center">
            <div className="flex items-center gap-4 pb-4">
              {association.picture ? (
                <ApiImage
                  path={association.picture}
                  alt={association.name}
                  className="aspect-square w-40 rounded-lg border-2 border-primary-900 object-cover"
                />
              ) : (
                <div className="flex aspect-square w-40 items-center justify-center rounded-lg bg-secondary font-serif text-3xl uppercase text-secondary-50">
                  {association.name.charAt(0)}
                </div>
              )}
              <div>
                <h3 className="py-2 font-semibold">{association.name}</h3>
                <p className="text-gray-500">{association.description}</p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <IconBadge icon={FaArrowTrendUp} className="h-7 w-7" />
                <p className="text-[15px] font-medium">
                  {t('numberOfCommunities')}: {association.businessIds.length}
                </p>
              </div>
              {association.address && (
                <div className="flex items-center gap-2">
                  <IconBadge icon={FaLocationArrow} className="h-7 w-7" />
                  <p className="text-[15px] font-medium">
                    <a
                      href={`https://www.google.com/maps?q=${encodeURIComponent(`${association.address.street} ${association.address.houseNumber} 
                      ${association.address.city}, ${association.address.country}`)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 transition hover:text-red-500 hover:underline">
                      {association.address.street} {association.address.houseNumber},{' '}
                      {association.address.city}, {association.address.country}
                    </a>
                  </p>
                </div>
              )}
              {association.website && (
                <div className="flex items-center gap-2">
                  <IconBadge icon={FaAt} className="h-7 w-7" />
                  <p className="text-[15px] font-medium text-blue-500 transition hover:text-red-500 hover:underline">
                    <a href={association.website} target="_blank" rel="noreferrer">
                      {association.website}
                    </a>
                  </p>
                </div>
              )}
              {selectedBusiness?.businessId &&
              !association.businessIds.includes(selectedBusiness.businessId) ? (
                <Button
                  variant="primary"
                  className="mt-1"
                  onClick={() => {
                    associationsRaceService
                      .joinAssociation(+associationId)
                      .then(() => fetchAssociation());
                  }}>
                  {t('join')}
                </Button>
              ) : (
                <Button
                  variant="danger-v2"
                  className="mt-1"
                  onClick={() =>
                    associationsRaceService
                      .leaveAssociation(+associationId)
                      .then(() => fetchAssociation())
                  }>
                  {t('leave')}
                </Button>
              )}
              {role === 'ADMIN' ? (
                <>
                  <Button
                    variant="primary"
                    className="mt-1"
                    onClick={() => {
                      setAddAssociationModalOpen(true);
                    }}>
                    edit
                  </Button>
                  <Button
                    variant="primary"
                    className="mt-1"
                    onClick={() => {
                      associationsRaceService.deleteAssociation(+associationId);
                      navigate('/race');
                    }}>
                    delete
                  </Button>
                </>
              ) : (
                <></>
              )}
              {associationId && (
                <AssociationModal
                  open={addAssociationModalOpen}
                  setOpen={setAddAssociationModalOpen}
                  image={image}
                  modalField={modalField}
                  setImage={setImage}
                  setName={setName}
                  setDescription={setDescription}
                  setWebsite={setWebsite}
                  setModalField={setModalField}
                  handleSubmit={handleSubmit}
                />
              )}
            </div>
          </div>
          <div className="h-80 w-80 max-md:h-72 max-md:w-72">
            <Chart currentTrialMonths={association.businessIds.length} />
          </div>
        </div>
        <h3 className="text-lg font-semibold">{t('participatingCommunities')}</h3>
        <div className="flex flex-wrap gap-4">
          {association.businesses.map((community) => (
            <BusinessCard business={community} />
          ))}
        </div>
      </main>
    </ApplicationLayout>
  );
}

function Chart({ currentTrialMonths }: { currentTrialMonths: number }): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.race.association' });

  return (
    <ResponsiveContainer>
      <PieChart margin={{ top: 20, bottom: 10 }}>
        <Pie
          data={[
            { value: currentTrialMonths },
            { value: MAXIMUM_COMMUNITIES_PER_ASSOCIATION - currentTrialMonths },
          ]}
          dataKey="value"
          innerRadius="75%"
          outerRadius="100%"
          startAngle={90}
          endAngle={-270}
          stroke="none"
          paddingAngle={2}>
          <Cell fill={COLOR_PRIMARY_300} opacity={1} />
          <Cell fill={COLOR_PRIMARY_300} opacity={0.4} />
        </Pie>
        <circle cx="50%" cy="51%" fill={COLOR_PRIMARY_900} r={70} />
        <text
          x="50%"
          y="50%"
          dy={-10}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="28"
          fontWeight="bold"
          fill={COLOR_SECONDARY_50}>
          {currentTrialMonths}/{MAXIMUM_COMMUNITIES_PER_ASSOCIATION}
        </text>
        <text x="50%" y="50%" dy={20} textAnchor="middle" fontSize="16" fill={COLOR_SECONDARY_50}>
          {t('months')}
        </text>
        <text x="50%" y="50%" dy={38} textAnchor="middle" fontSize="16" fill={COLOR_SECONDARY_50}>
          {t('acquired')}
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
}

import React from 'react';
import { IconType } from 'react-icons';
import { PropsOf } from '@headlessui/react/dist/types';
import { twMerge } from 'tailwind-merge';
import Button from './Button';

export type PrimaryButtonProps = {
  text: string;
  icon?: IconType;
} & Omit<PropsOf<typeof Button>, 'children' | 'variant'>;

export default function PrimaryButton({
  icon: Icon,
  text,
  className,
  ...props
}: PrimaryButtonProps): JSX.Element {
  return (
    <Button variant="primary" className={twMerge('text-base', className)} {...props}>
      {Icon && <Icon className="h-5 w-5 flex-shrink-0" />}
      {text}
    </Button>
  );
}

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight, FaArrowTrendUp, FaLocationArrow, FaUserGroup } from 'react-icons/fa6';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ApplicationLayout from '../../components/layouts/ApplicationLayout/ApplicationLayout';
import Button from '../../components/buttons/Button';
import ModalLayout from '../../components/layouts/ModalLayout';
import IconBadge from '../../components/icons/IconBadge';
import Labeled from '../../components/misc/Labeled';
import ImageInput from '../../components/forms/ImageInput';
import AddressInput from '../../components/forms/profile/AddressInput';
import { createEmptyField } from '../../../utils/formatUtils';
import { ProfileDataType, ProfileField } from '../../../types/Profile';
import { associationsRaceService } from '../../../services/associationsRaceService';
import { Association } from '../../../types/race';
import ApiImage from '../../components/misc/ApiImage';
import { RootState } from '../../../redux/reducers';

export default function RaceOverviewPage(): JSX.Element {
  const [addAssociationModalOpen, setAddAssociationModalOpen] = React.useState(false);
  const [image, setImage] = useState<string | undefined>();
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [website, setWebsite] = React.useState('');
  const [associations, setAssociations] = React.useState<Association[]>([]);
  const [unapprovedAssociations, setUnapprovedAssociations] = React.useState<Association[]>([]);
  const role = useSelector((state: RootState) => state.application.role);
  const navigate = useNavigate();
  const { t } = useTranslation('', { keyPrefix: 'page.race.overview' });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    associationsRaceService
      .createAssociation(name, description, website, image, modalField?.address)
      .then(() => {
        setAddAssociationModalOpen(false);
        fetchAssociations();
      });
  };

  const fetchAssociations = async () => {
    associationsRaceService.getAllAssociations().then(setAssociations);

    if (role === 'ADMIN') {
      associationsRaceService.getUnapproved().then(setUnapprovedAssociations);
    }
  };

  const [modalField, setModalField] = useState<ProfileField>();

  // change text like in click up
  // add check whether to email or create immediately

  useLayoutEffect(() => {
    if (modalField) setModalField(modalField);
    else setModalField(createEmptyField(ProfileDataType.ADDRESS));
  }, []);

  useEffect(() => {
    fetchAssociations();
  }, []);

  return (
    <ApplicationLayout>
      <div className="flex flex-col gap-4">
        {role === 'ADMIN' &&
          unapprovedAssociations.map((a) => (
            <div className="flex items-center gap-4">
              <p>{a.name}</p>
              <p>{a.description}</p>
              <p>{a.website}</p>
              <p>{a.address.city}</p>
              <Button
                variant="secondary"
                onClick={() => {
                  associationsRaceService
                    .approve(a.associationId)
                    .then(() => fetchAssociations())
                    .then(() => toast.success('Association approved'));
                }}>
                Approve
              </Button>
              <Button
                variant="danger-v2"
                onClick={() => {
                  associationsRaceService
                    .declineApproval(a.associationId)
                    .then(() => fetchAssociations())
                    .then(() => toast.error('Association declined'));
                }}>
                Decline
              </Button>
            </div>
          ))}
        <div className="flex flex-col gap-2 rounded-xl bg-secondary-200 bg-opacity-50 p-4">
          <h1 className="font-serif text-2xl">{t('title')}</h1>
          <p className="text-gray-500">{t('subtitle')}</p>
          <h3 className="font-bold">{t('rules')}:</h3>
          <ul className="list-inside list-decimal">
            <li className="list-item">{t('ruleOne')}</li>
            <li className="list-item">{t('ruleTwo')}</li>
            <li className="list-item">{t('ruleThree')}</li>
            <li className="list-item">{t('ruleFour')}</li>
          </ul>
        </div>
        <div className="flex flex-wrap items-center justify-between gap-2">
          <h2 className="text-lg font-bold">{t('associationsTitle')}</h2>
          {/*<SearchBar />*/}
        </div>
        <div>
          <p>{t('associationsSubtitle')}</p>
          <Trans i18nKey="page.race.overview.associationsHelper">
            Dont see your association? Click
            <button
              type="button"
              onClick={() => setAddAssociationModalOpen(true)}
              className="inline-block font-semibold underline hover:opacity-75">
              here
            </button>
            to add it!
          </Trans>
        </div>
        <div className="flex flex-wrap gap-2">
          {associations.map((x) => (
            <div className="flex w-96 flex-col rounded-lg border-2 border-secondary-200 bg-secondary-200 bg-opacity-50 p-4">
              <div className="flex items-center gap-4 pb-4">
                {x.picture ? (
                  <ApiImage
                    path={x.picture}
                    alt={x.name}
                    className="aspect-square w-28 rounded-lg border-2 border-primary-900 object-cover"
                  />
                ) : (
                  <div className="flex aspect-square w-28 items-center justify-center rounded-lg bg-secondary font-serif text-3xl uppercase text-secondary-50">
                    {x.name.charAt(0)}
                  </div>
                )}
                <div>
                  <h3 className="py-2 font-semibold">{x.name}</h3>
                  <p className="text-sm text-gray-500">
                    {x.description.length > 100
                      ? `${x.description.slice(0, 100)}...`
                      : x.description}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <IconBadge icon={FaArrowTrendUp} className="h-7 w-7" />
                  <p className="text-[15px] font-medium">
                    {t('numberOfCommunities')}: {x.businessIds.length}
                  </p>
                </div>
                {x.address && (
                  <div className="flex items-center gap-2">
                    <IconBadge icon={FaLocationArrow} className="h-7 w-7" />
                    <p className="text-[15px] font-medium">
                      {x.address.city}, {x.address.country}
                    </p>
                  </div>
                )}
              </div>
              <Button
                onClick={() => navigate(`/race/association/${x.associationId}`)}
                variant="primary"
                className="mt-4 self-end text-sm">
                To association page <FaArrowRight size={16} />
              </Button>
            </div>
          ))}
        </div>
      </div>
      <AssociationModal
        open={addAssociationModalOpen}
        setOpen={setAddAssociationModalOpen}
        image={image}
        modalField={modalField}
        setImage={setImage}
        setName={setName}
        setDescription={setDescription}
        setWebsite={setWebsite}
        setModalField={setModalField}
        handleSubmit={handleSubmit}
      />
    </ApplicationLayout>
  );
}

interface AssociationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  image: string | undefined;
  setImage: (img: string | undefined) => void;
  setName: (name: string) => void;
  setDescription: (description: string) => void;
  setWebsite: (website: string) => void;
  modalField: ProfileField | undefined;
  setModalField: (field: ProfileField) => void;
}

export function AssociationModal({
  open,
  setOpen,
  image,
  modalField,
  setImage,
  setName,
  setDescription,
  setWebsite,
  setModalField,
  handleSubmit,
}: AssociationModalProps) {
  return (
    <>
      <ModalLayout
        closeButton
        open={open}
        setOpen={setOpen}
        className="z-50 mx-6 my-8 w-full rounded-[20px] bg-secondary-50 p-8 sm:max-w-[700px]">
        <div className="flex border-b border-secondary-200">
          <IconBadge icon={FaUserGroup} />
          <div className="mb-4 ml-4 flex w-full flex-col">
            <h2 className="font-serif text-2xl">Request to add an association</h2>
            <p>Explanation</p>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="mt-4 flex w-full flex-col gap-4">
          <Labeled label="Image">
            <ImageInput
              className="h-14"
              image={image || ''}
              setImage={(img) => {
                setImage(img);
              }}
            />
          </Labeled>
          <Labeled label="Name">
            <input
              type="text"
              placeholder="Name of the association"
              name="name"
              onInput={(e: React.FormEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
            />
          </Labeled>
          <Labeled label="Description">
            <input
              type="text"
              placeholder="Student of studie"
              name="name"
              onInput={(e: React.FormEvent<HTMLInputElement>) =>
                setDescription(e.currentTarget.value)
              }
            />
          </Labeled>
          <Labeled label="Website">
            <input
              type="url"
              placeholder="Website"
              name="name"
              onInput={(e: React.FormEvent<HTMLInputElement>) => setWebsite(e.currentTarget.value)}
            />
          </Labeled>
          {modalField && <AddressInput field={modalField} setField={setModalField} />}
          <Button type="submit" variant="primary" className="self-end">
            Submit request
          </Button>
        </form>
      </ModalLayout>{' '}
    </>
  );
}

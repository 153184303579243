import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ContactsPage from '../views/pages/ContactsPage';
import LoginPage from '../views/pages/auth/LoginPage';
import AuthenticatedRoute from './AuthenticatedRoute';
import NotFoundPage from '../views/pages/NotFoundPage';
import NavBar from '../views/components/layouts/NavBar';
import MagicLoginPage from '../views/pages/auth/MagicLoginPage';
import AddressLabelPage from '../views/pages/payment/AddressLabelPage';
import PaymentCompletionPage from '../views/pages/payment/PaymentCompletionPage';
import Footer from '../views/components/layouts/Footer';
import BusinessOverviewPage from '../views/pages/BusinessOverviewPage';
import SelectedBusinessRoute from './SelectedBusinessRoute';
import GeneralSettingsPage from '../views/pages/settings/GeneralSettingsPage';
import RequestedDataPage from '../views/pages/settings/RequestedDataPage';
import AcceptAdminInvitePage from '../views/pages/AcceptAdminInvitePage';
import AdminOverviewPage from '../views/pages/settings/AdminOverviewPage';
import useAuth from '../hooks/account/useAuth';
import ProfilePage from '../views/pages/ProfilePage';
import OnboardingPage from '../views/pages/flows/OnboardingPage';
import LandingPage from '../views/pages/info/LandingPage';
import AboutPage from '../views/pages/info/AboutPage';
import ApplicationLayout from '../views/components/layouts/ApplicationLayout/ApplicationLayout';
import { isCommunity, isMoments } from '../constants';
import SharedDataPage from '../views/pages/settings/SharedDataPage';
import InviteLinkPage from '../views/pages/flows/InviteLinkPage';
import EventsPage from '../views/pages/agenda/EventsPage';
import EventReportsPage from '../views/pages/agenda/EventReportsPage';
import CreateEventPage from '../views/pages/agenda/CreateEventPage';
import EventDetailsPage from '../views/pages/agenda/EventDetailsPage';
import UploadExcelPage from '../views/pages/settings/UploadExcelPage';
import { SubscriptionsPage } from '../views/pages/payment/SubscriptionsPage';
import { EventViewType } from '../types/event';
import ExportExcelPage from '../views/pages/settings/ExportExcelPage';
import EventsOverviewPage from '../views/pages/agenda/EventsOverviewPage';
import FeedbackPage from '../views/pages/FeedbackPage';
import IntegrationsPage from '../views/pages/IntegrationsPage';
import UpgradeSubscriptionPage from '../views/pages/payment/UpgradeSubscriptionPage';
import ManageSubscriptionPage from '../views/pages/settings/ManageSubscriptionPage';
import InvoicesPage from '../views/pages/payment/InvoicesPage';
import OverviewPage from '../views/pages/OverviewPage';
import RaceOverviewPage from '../views/pages/race/RaceOverviewPage';
import AssociationPage from '../views/pages/race/AssociationPage';
import ConfigureIntegrationsPage from '../views/pages/ConfigureIntegrationsPage';
import ScoreListsPage from '../views/pages/lists/ScoreListsPage';
import TaskOverviewPage from '../views/pages/lists/TaskOverviewPage';
import CreateCommunicationPage from '../views/pages/communication/CreateCommunicationPage';
import CommunicationsPage from '../views/pages/communication/CommunicationsPage';
import ActivateDashboardPage from '../views/pages/flows/ActivateDashboardPage';

export default function Router(): JSX.Element {
  const auth = useAuth();
  const location = useLocation().pathname;

  return (
    <>
      {(!auth || location === '/admin/invite') &&
        location !== '/onboarding' &&
        !location.startsWith('/race') && <NavBar />}
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="onboarding" element={<OnboardingPage />} />
        <Route path="magic" element={<MagicLoginPage />} />
        <Route path="/admin/invite" element={<AcceptAdminInvitePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/race" element={<RaceOverviewPage />} />
        <Route path="/race/association/:associationId" element={<AssociationPage />} />
        {!auth && <Route path="" element={<LandingPage />} />}
        <Route path="" element={<AuthenticatedRoute />}>
          <Route path="" element={<ApplicationLayout />}>
            <Route path="" element={<BusinessOverviewPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/integrations" element={<IntegrationsPage />} />
            <Route path="/dashboard/activate" element={<ActivateDashboardPage />} />
            <Route path="/integrations/manage" element={<ConfigureIntegrationsPage />} />
            <Route path="" element={<SelectedBusinessRoute />}>
              <Route path="/overview" element={<OverviewPage />} />
              <Route path="/contacts" element={<ContactsPage />} />
              <Route path="/settings" element={<GeneralSettingsPage />} />
              <Route path="/invite-link" element={<InviteLinkPage />} />
              <Route path="/settings/requested-data" element={<RequestedDataPage />} />
              <Route path="/feedback" element={<FeedbackPage />} />
              {isMoments && <Route path="/settings/shared" element={<SharedDataPage />} />}
              <Route path="/settings/upload-excel" element={<UploadExcelPage />} />
              <Route path="/settings/export-excel" element={<ExportExcelPage />} />
              <Route path="/settings/admins" element={<AdminOverviewPage />} />
              {isMoments && <Route path="/payment/address-label" element={<AddressLabelPage />} />}
              <Route path="/payment/completion" element={<PaymentCompletionPage />} />
              {isCommunity && (
                <>
                  <Route path="/settings/subscriptions" element={<SubscriptionsPage />} />
                  <Route path="/subscriptions/upgrade" element={<UpgradeSubscriptionPage />} />
                  <Route path="/subscriptions/manage" element={<ManageSubscriptionPage />} />
                  <Route path="/subscriptions/invoices" element={<InvoicesPage />} />
                  {/* Regular events */}
                  <Route path="/events" element={<EventsOverviewPage />} />
                  <Route
                    path="/events/create"
                    element={<CreateEventPage type={EventViewType.REGULAR} />}
                  />
                  <Route
                    path="/events/details"
                    element={<EventDetailsPage type={EventViewType.REGULAR} />}
                  />

                  {/* Date picker */}
                  <Route
                    path="/events/date-picker"
                    element={<EventsPage type={EventViewType.DATE_PICKER} />}
                  />
                  <Route
                    path="/events/date-picker/create"
                    element={<CreateEventPage type={EventViewType.DATE_PICKER} />}
                  />
                  <Route
                    path="/events/date-picker/details"
                    element={<EventDetailsPage type={EventViewType.DATE_PICKER} />}
                  />

                  <Route path="/events/report" element={<EventReportsPage />} />

                  {/* Lists */}
                  <Route path="/lists/score" element={<ScoreListsPage />} />
                  <Route path="/lists/task" element={<TaskOverviewPage />} />
                </>
              )}
              <Route path="/communication" element={<CommunicationsPage />} />
              <Route path="/communication/create" element={<CreateCommunicationPage />} />
              <Route path="/communication/edit" element={<CreateCommunicationPage />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      {(!auth || location === '/admin/invite') &&
        location !== '/onboarding' &&
        !location.startsWith('/race') && <Footer />}
    </>
  );
}

import { Menu, Transition } from '@headlessui/react';
import { HiMiniEllipsisHorizontal } from 'react-icons/hi2';
import React, { Children, Fragment } from 'react';
import { TbX } from 'react-icons/tb';
import { IconType } from 'react-icons';
import { PropsOf } from '@headlessui/react/dist/types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

export type ActionMenuProps = {
  children?: React.ReactNode;
};

export function ActionMenu({ children }: ActionMenuProps): JSX.Element {
  const { t } = useTranslation('');
  const numberOfOptions = Children.toArray(children).length;

  return (
    <Menu as="div" className="relative flex items-center">
      {({ close }) => (
        <>
          <Menu.Button className="flex items-center justify-center">
            <HiMiniEllipsisHorizontal className="icon h-8 w-8 font-bold" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute top-6 z-10 h-fit max-h-48 min-w-52 -translate-x-[calc(100%-2rem)] overflow-y-scroll rounded-md bg-secondary-50 shadow-lg">
              <div className="flex items-center justify-between px-4 py-2 font-semibold">
                <p>{t('general.options')}</p>
                <button
                  className="flex items-center justify-center hover:opacity-80"
                  type="button"
                  onClick={close}>
                  <TbX className="h-5 w-5 flex-shrink-0" />
                </button>
              </div>
              {numberOfOptions === 0 && (
                <p className="px-4 py-2 text-sm text-gray-500">{t('general.noOptions')}</p>
              )}
              {children}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export type ActionMenuButtonProps = { icon: IconType; text: string } & Omit<
  PropsOf<'button'>,
  'type'
>;

export function ActionMenuButton({
  icon: Icon,
  text,
  className,
  ...props
}: ActionMenuButtonProps): JSX.Element {
  return (
    <Menu.Item>
      <button
        type="button"
        className={twMerge(
          'flex w-full items-center gap-2 whitespace-nowrap px-4 py-2 text-sm font-semibold transition-colors last:rounded-b-md hover:bg-secondary-200',
          className,
        )}
        {...props}>
        <Icon className="icon h-5 w-5 flex-shrink-0" />
        {text}
      </button>
    </Menu.Item>
  );
}

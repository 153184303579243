import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IoCopy } from 'react-icons/io5';
import ModalLayout from '../layouts/ModalLayout';
import Button from '../buttons/Button';
import eventService from '../../../services/eventService';
import { buildLink } from '../../../utils/eventUtils';
import { Event } from '../../../types/event';
import { formatDateAndTime } from '../../../utils/stringUtils';
import WhatsappButton from '../buttons/WhatsappButton';

type EventShareModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  event: Event;
};

export default function EventShareModal({ open, setOpen, event }: EventShareModalProps) {
  const { t } = useTranslation();

  const [message, setMessage] = useState(
    t('page.eventDetails.shareModal.message', {
      title: event.title,
      date: formatDateAndTime(event.startTime),
    }),
  );
  const [link, setLink] = useState('');

  useEffect(() => {
    if (!open || link) return;
    eventService.generateEventInviteLink(event.id).then((l) => setLink(buildLink(l)));
  }, [open]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${message} \n${link}`);
    toast.success(t('toast.success.linkCopied'));
    setOpen(false);
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="z-50 mx-6 flex min-w-[380px] max-w-[600px] flex-col justify-center gap-4 rounded-[20px] bg-secondary-50 px-6 py-8 lg:px-12">
      <div className="mb-4 flex flex-col gap-2">
        <h3 className="font-serif text-xl font-semibold">
          {t('page.eventDetails.shareModal.title')}
        </h3>
        <p className="text">{t('page.eventDetails.shareModal.subtitle')}</p>
      </div>

      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="no-scrollbar h-[80px] resize-none"
      />

      <Button variant="primary" className="w-full justify-center" onClick={handleCopyLink}>
        <IoCopy />
        {t('page.eventDetails.shareModal.button')}
      </Button>
      <WhatsappButton className="w-full justify-center" text={`${message} \n${link}`} />

      <Button className="w-full justify-center underline" onClick={() => setOpen(false)}>
        {t('general.cancel')}
      </Button>
    </ModalLayout>
  );
}
